import React from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import { Outlet } from 'react-router-dom';
import ScrollToTop from '../ScrollToTop';
function Layout({ children }) {
    return (
        <>
            {/* <Marquee gradient={true} gradientWidth={190} style={{ color: '#28608f', height: '20px', position: 'absolute', overflow: 'hidden' }}>
                Dear Users, We are currently experiencing issues with our SMS gateway, which may affect communication. For any urgent assistance or bookings, please contact our support team directly at +91 99722 57154. We apologize for the inconvenience and appreciate your understanding. 
            </Marquee> */}
            <Header />
            <div style={{ marginTop: '70px' }}>
                <ScrollToTop />
                <Outlet />
            </div>
            <div>
                <Footer />
            </div>
        </>
    )
}

export default Layout