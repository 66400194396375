import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from "react-helmet";
import SchemaOrg from '../../components/schema.org/SchemaOrg';
function Contact() {
    return (
        <>
            <SchemaOrg />
            <Helmet>
                <title>Contact Us- HireCabs | Contact hireabs  | help on hirecabs
                </title>

                <link rel="canonical" href="https://www.hirecabs.in/contact-us" />
                <meta name="description"
                    content="Contact Us HireCabs, connect here for any enuiries, bookings or related to add as peartner " />
                <meta name="keywords"
                    content="hirecab helpline, hirecabs email, hirecabs contact no, hirecabs contact us " />
                <meta name="keyphrase"
                    content="get hirecabs helpine numebr, hirecabs contact form, hirecabs contact details" />
                <meta property="og:type" content="business.business" />
                <meta property="og:title" content="Contact Us HireCabs - Your Trusted Taxi Service Provider in Bangalore" />
                <meta property="og:description"
                    content="get hirecabs contact page, hirecab help line number" />
                <meta name="google-site-verification" content="XdmMXsbw0NHq_-nGHCDViua2k6RTMUs2RKHI" />
                <meta property="og:url" content="https://www.hirecabs.in/contact-us" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />

            </Helmet>
            <main className="main">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <ul className="breadcrumbs">
                                <li className="breadcrumbs__item"><Link to={'/'}>Home</Link></li>
                                <li className="breadcrumbs__item breadcrumbs__item--active">Contact Us</li>
                            </ul>
                        </div>

                        <div className="col-12 col-xl-11">
                            <div className="main__title main__title--page">
                                <h1>Contact Us</h1>
                                <p>Here you will find answers to frequently asked questions about our company and services. If you don't find the answer to your question, please contact our customer support and we'll be happy to assist you.        <Link to={'/help'}>Ask a question</Link></p>
                            </div>
                        </div>

                    </div>
                </div>
            </main>
        </>
    )
}

export default Contact