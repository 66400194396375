import React, { useState, useEffect } from 'react'
import { homePageService, useGetMyOrdersListQuery } from '../../services/homePageService';
import './MyAccount.scss';
import { Link } from 'react-router-dom'
import * as moment from 'moment'
import Accordion from 'react-bootstrap/Accordion';
import userpng from '../../assets/images/user.png';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useParams } from 'react-router-dom';
import nodatasvg from '../../assets/images/nodata.svg'
import { useCancelOrderMutation } from '../../services/homePageService';
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import closesvg from '../../assets/images/close.svg';
import { titleCase } from '../../components/Utilities/Utilities';

function MyAccount() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [carDataItem, setCarDataItem] = useState([]);
    const [ordersData, setOrdersData] = useState([]);
    const [oldOrders, setOldOrders] = useState([]);
    const [userProfile, setUserProfile] = useState([]);
    const [reschedule, setReschedule] = useState(false);
    const { data, error, isLoading, refetch } = useGetMyOrdersListQuery();
    const [loading, setIsLoading] = useState(false);
    function capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }

    const rescheduleTrip = () => {
        setReschedule(true);
    }

    const openModal = (id) => {
        setCarDataItem(ordersData.find(x => x.id === id))
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };


    const [dateshow, setShow] = useState(false);
    const [returnDate, setReturnDate] = useState('');
    const [dateshowTodate, setShowtodate] = useState(false);
    const [cancelOrder] = useCancelOrderMutation();


    const handleShow = () => {
        setShow(true);
        setTimeout(
            () => { document.querySelectorAll(".react-datepicker__input-container input")[0].click() },
            100
        )
    }

    const todateShow = () => {
        setShowtodate(true);
        setTimeout(
            () => { document.querySelectorAll(".react-datepicker__input-container input")[0].click() },
            100
        )
    }
    const cancelBooking = async (id) => {
        if (window.confirm("are you sure you want to Cancel?")) {
            try {
                setIsLoading(true);

                // Dispatch the mutation with the new resource data
                const result = await cancelOrder({ id: id });

                if (!result.data.success) {
                    toast.error(result.data.msg);
                }
                else if (result.data.success) {
                    toast.success('Cancelled Successfully!');
                }

                // setCarData(carData => [...carData, { totalKm: result.data }]);
            } catch (error) {
                // Handle error
                console.error('Error creating resource:', error);
            }
            finally {
                setIsLoading(false) //to turn off loading
            }
        }
    };



    useEffect(() => {
        if (data) {
            setOrdersData(data.user);
            setOldOrders(data.oldOrders);
            setUserProfile(data.userProfile);
        }

        const fetchData = async () => {
            try {
                await refetch();
            } catch (error) {
            }
        };

        fetchData();
        const intervalId = setInterval(fetchData, 60000);
        return () => clearInterval(intervalId);


        console.log("carDataItem ", carDataItem);

    }, [data, carDataItem]);

    if (isLoading || loading) {
        return (
            <div className="loader-container">
                <div className="loader"></div>
            </div>
        );
    }

    if (isLoading) {
        return (
            <div className="loader-container">
                <div className="loader"></div>
            </div>
        );
    }


    return (
        <>
            <main className="main">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <ul className="breadcrumbs">
                                <li className="breadcrumbs__item"><Link to={'/'}>Home</Link></li>
                                <li className="breadcrumbs__item breadcrumbs__item--active">My Account</li>
                            </ul>
                        </div>

                        <div className="col-12 my__account">

                            <Tabs>
                                <TabList>
                                    <div className="nav nav-tabs profile__tabs">
                                        {/* <ul className="nav nav-tabs profile__tabs" id="profile__tabs" role="tablist"> */}
                                        <Tab className="nav-item" role="presentation">
                                            <button data-bs-toggle="tab" data-bs-target="#tab-1" type="button" role="tab" aria-controls="tab-1" aria-selected="true">Ongoing Trips {ordersData && (
                                                ordersData.length > 0 ? '(' + ordersData.length + ')' : ''
                                            )}</button>

                                        </Tab>
                                        <Tab className="nav-item" role="presentation">

                                            <button data-bs-toggle="tab" data-bs-target="#tab-2" type="button" role="tab" aria-controls="tab-2" aria-selected="false">Completed Trips {oldOrders && (
                                                oldOrders.length > 0 ? '(' + oldOrders.length + ')' : ''
                                            )}
                                            </button>
                                        </Tab>
                                        <Tab className="nav-item" role="presentation">

                                            <button data-bs-toggle="tab" data-bs-target="#tab-2" type="button" role="tab" aria-controls="tab-2" aria-selected="false">My Profile</button>

                                        </Tab>
                                    </div>

                                </TabList>
                                <TabPanel>
                                    <div className="dr_tab_orders" >
                                        {ordersData && ordersData.length > 0 ? ordersData.map((result, index) => (
                                            <>
                                                <div className="card-1" key={result.id}>
                                                    <div className="card-1-item-1">
                                                        {result.trip_type === 'oneway' && (
                                                            <>
                                                                <span className="datetimebold">{result.pickup_dtime ? moment(result.pickup_dtime).format('DD MMM YYYY, hh:mm A') : ''}</span> <span className="right-align"># {result.id}</span><br />
                                                                <div className="vtype-text">{result.car_type}</div>
                                                            </>
                                                        )}
                                                        {result.trip_type === 'roundtrip' && (
                                                            <>
                                                                <span className="datetimebold">{result.pickup_dtime ? moment(result.pickup_dtime).format('DD MMM YYYY, hh:mm A') : ''}</span> <span className="right-align"># {result.id}</span><br />
                                                                <div className="vtype-text">Pikcup Date</div>
                                                                <span className="datetimebold">{result.return_date ? moment(result.return_date).format('DD MMM YYYY, hh:mm A') : ''}</span>
                                                                <div className="vtype-text">Return Date</div>
                                                                <div className="vtype-text">{result.car_type}</div>
                                                            </>
                                                        )}
                                                    </div>
                                                    <div className="card-1-item-2">
                                                        <table>
                                                            <tbody>
                                                                <tr>
                                                                    <td className='font-wt-800' colSpan={2}>
                                                                        {result.trip_type === 'roundtrip' ? 'Round Trip' : 'One Way'} Voyage ({titleCase(result.drv_car_type)})
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td colSpan="2" ></td>
                                                                </tr>
                                                                <tr className="dr-border-top">
                                                                    <td className="dr-bold font-wt-800">Grand Total</td>
                                                                    <td className="dr-bold font-size-20 grand-total-price font-wt-800">₹ {Number(result.totalAmount)}
                                                                        <div className='breakup-text' onClick={() => openModal(result.id)}>Breakup</div></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <br />

                                                    <div className="card-2-item-1">
                                                        <div className="dr-tag-line">Pickup From</div>
                                                        <span> {result.pickup_loc} </span>
                                                    </div>

                                                    <div className="card-2-item-1 m-t-10">
                                                        <div className="dr-tag-line">To Location</div>
                                                        <span> {result.to_loc} </span>
                                                    </div>
                                                    <div className="card-2-item-2">
                                                        <div className="tbl-wrap">
                                                            <table>
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="active">Booking Confirmed</td>

                                                                        <td className='dr__date__time'>  {result.order_date ? moment(result.order_date).format('DD MMM, hh:mm A') : ''}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className={(result.driver_accepted_time ? 'active' : '')}>Driver Accepted</td>
                                                                        <td className='dr__date__time'>  {result.driver_accepted_time ? moment(result.driver_accepted_time).format('DD MMM, hh:mm A') : ''}
                                                                        </td>

                                                                    </tr>

                                                                    <tr>
                                                                        <td className={(result.reached_time ? 'active' : '')}>Driver Reached</td>
                                                                        <td className='dr__date__time'>
                                                                            {result.reached_time ? moment(result.reached_time).format('DD MMM, hh:mm A') : ''}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className={(result.trip_started ? 'active' : '')}>Trip Started</td>
                                                                        <td className='dr__date__time'>  {result.trip_started ? moment(result.trip_started).format('DD MMM, hh:mm A') : ''}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="">Trip Ended</td>
                                                                        <td></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    {result.partner_name && (

                                                        <div className="card-3-item-1">
                                                            <div className="dr-bold">Partner Details </div>
                                                            <table>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            <div className="card-profile-img">
                                                                                <img src={userpng} />
                                                                            </div>

                                                                        </td>
                                                                        <td>
                                                                            {result.partner_name}

                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td colSpan={2} className='text-center'>
                                                                            <div className='call-opt'>
                                                                                <a href={`tel:${result.partner_mobile}`}  >
                                                                                    <box-icon size='md' name='phone' type='solid' color='#28608f' ></box-icon>
                                                                                </a>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>

                                                        </div>

                                                    )}
                                                    <div className="card-4-item-1">
                                                        {!reschedule && (
                                                            <ul>
                                                                <li onClick={() => cancelBooking(result.id)}>
                                                                    Cancel
                                                                </li>
                                                                {/* <li onClick={rescheduleTrip}>
                                                                    Reschedule
                                                                </li> */}
                                                            </ul>
                                                        )}
                                                        {reschedule && (
                                                            <div className="igo__reshecule">
                                                                <div>

                                                                    <label htmlFor="search2">Pickup Date</label>
                                                                    <div className='datepicker_div'>
                                                                        <input type="text" name="fromdate" value={result.pickup_dtime ? moment(result.pickup_dtime).format('DD-MMM-YYYY, hh:mm A') : ''} id="search2" readOnly onClick={handleShow} placeholder="Pickup Date & time" />
                                                                    </div>

                                                                </div>
                                                                <div>
                                                                    {result.trip_type === 'roundtrip' && (
                                                                        <div className="" >
                                                                            <label htmlFor="search3">Return Date</label>
                                                                            <input type="text" name="toDate" onClick={todateShow} id="search3" value={result ? moment(result.return_date).format('DD-MMM-YYYY, hh:mm A') : ''} readOnly placeholder="Return date & time" />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>

                                                </div >

                                            </>
                                        )) : <>
                                            <div className='no-data'>
                                                <div className='img-nodata'>
                                                    <img src={nodatasvg} />
                                                </div>
                                                <h1 className="margin-y-2xs">No Orders Found</h1>
                                            </div>
                                        </>
                                        }

                                    </div>
                                </TabPanel>


                                <TabPanel>

                                    <Accordion >
                                        {oldOrders && oldOrders.length > 0 ? oldOrders.map((result, index) => (
                                            <>
                                                <Accordion.Item eventKey={index.toString()}>
                                                    <Accordion.Header> Trip {result.id} {result.status === '4' && (<span className='canceled-color'>&nbsp;(Cancelled)</span>)}</Accordion.Header>
                                                    <Accordion.Body style={{ paddingTop: '0' }}>
                                                        <div className="card-1" style={{ padding: '0' }}>
                                                            <div className="card-1-item-1">
                                                                <span className="datetimebold">{result.pickup_dtime ? moment(result.pickup_dtime).format('DD MMM YYYY, hh:mm A') : ''}</span> <span className="right-align"># {result.id}</span><br />

                                                                <div className="vtype-text">{result.drv_car_type}</div>

                                                            </div>
                                                            {result.status !== '4' && (
                                                                <>
                                                                    <div className="card-2-item-1">
                                                                        <div className="dr-tag-line">Pickup From</div>
                                                                        <span> {result.pickup_loc} </span>
                                                                    </div>
                                                                    <div className="card-2-item-1">
                                                                        <div className="dr-tag-line">Itinerary</div>
                                                                        <span> {result.to_loc} </span>
                                                                    </div>

                                                                </>
                                                            )}
                                                            <br />
                                                            <div className="card-1-item-2">
                                                                <table>
                                                                    <tr>
                                                                        <td className='capitalize-font'>{result.trip_type}</td>
                                                                        <td>₹ {(Number(result.totalAmount) - Number(result.total_gst))}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Total GST</td>
                                                                        <td>₹ {result.total_gst}</td>
                                                                    </tr>

                                                                    {/* <tr>
                                                                    <td>Dicount</td>
                                                                    <td>-{result.discount_applied}</td>
                                                                </tr> */}


                                                                    <tr>
                                                                        <td colSpan="2" ></td>
                                                                    </tr>
                                                                    <tr className="dr-border-top">
                                                                        <td className="dr-bold">Grand Total</td>
                                                                        <td className="dr-bold font-size-20">₹ {Number(result.totalAmount)}</td>
                                                                    </tr>
                                                                </table>
                                                            </div>

                                                        </div >
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </>
                                        ))
                                            : <>
                                                <div className='no-data'>
                                                    <div className='img-nodata'>
                                                        <img src={nodatasvg} />
                                                    </div>
                                                    <h1 className="margin-y-2xs">No Orders Found</h1>
                                                </div>
                                            </>

                                        }

                                    </Accordion>


                                </TabPanel>
                                <TabPanel >

                                    <div className="dr_tab_content dr-profile" >
                                        <div className='card-1'>
                                            <div className="dr-heading">My Profile</div>
                                        </div>
                                        {userProfile && (
                                            <div className='card-1'>
                                                <div className='card-1 row'>
                                                    <div className='col-md-4 '>
                                                        <div className='user_info'>
                                                            <box-icon size='150px' name='user-circle' type='solid' color='#28608f' ></box-icon>
                                                            <h2>{userProfile?.name}</h2>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-8'>
                                                        <div className='row disabled-cursor'>

                                                            <div className="col-md-6 col-lg-12 col-xl-6">
                                                                <div className="sign__group">
                                                                    <label className="sign__label" htmlFor="username">Email</label>
                                                                    <input type="text" name="name" className="sign__input" value={userProfile?.email} placeholder="User123" disabled />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 col-lg-12 col-xl-6">
                                                                <div className="sign__group">
                                                                    <label className="sign__label" htmlFor="username">Phone</label>
                                                                    <input type="text" name="phone" className="sign__input" value={userProfile?.phone} placeholder="User123" disabled />
                                                                </div>
                                                            </div>

                                                            <div className="col-md-12 col-lg-12 col-xl-12">
                                                                <div className="sign__group">
                                                                    <label className="sign__label" htmlFor="username">Address</label>

                                                                    <textarea name="address" className="sign__input" name="addr" value={userProfile?.pickup_locality} cols="30" rows="20" disabled></textarea>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                    </div>
                                </TabPanel>

                            </Tabs>

                        </div>

                    </div >
                    <Modal show={isModalOpen} onHide={closeModal} className="custom__modal">
                        <Modal.Header>
                            <div onClick={closeModal} className='imgclose'><img src={closesvg} /></div>
                        </Modal.Header>
                        <Modal.Body>
                            <table className='table_style'>
                                <tbody>
                                    <tr>
                                        <th colSpan={2}>Fare Breakup</th>
                                    </tr>
                                    <tr>
                                        <th>Paticular</th>
                                        <th>Fare</th>
                                    </tr>

                                    {/* {carDataItem.discountAmt && carDataItem.discountAmt > 0 && (
                        <tr>
                            <td>Discount</td>
                            <td>₹{carDataItem.discountAmt}</td>
                        </tr>
                    )} */}
                                    <tr>
                                        <td>Total Amt</td>
                                        <td>₹{carDataItem.totalAmount}</td>
                                    </tr>
                                    <tr>
                                        <th colSpan={2}>INCLUDES</th>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>Base Fare Up to {parseInt(carDataItem.min_km_included) > parseInt(carDataItem.totalKm) ? carDataItem.min_km_included : carDataItem.totalKm} km</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>Driver day bata and GST</td>
                                    </tr>
                                    <tr>
                                        <th colSpan={2}>EXCLUDES </th>
                                    </tr>

                                    <tr >
                                        <td colSpan={2} >Pay ₹ {carDataItem.extra_per_km_charges_with_gst_company} / km after {carDataItem.totalKm} km</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>Tolls and Parking (Permits or Intercity tax)</td>
                                    </tr>

                                    <tr>
                                        <th colSpan={2}>TERMS</th>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>Your Trip has a KM limit. If your usage exceeds this limit, you will be charged for the excess KM used.</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>The Airport Entry/Parking charge, if applicable is not included in the fare and will be charged extra.</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>Your trip includes one pick up in Pick-up city and one drop to destination city. It does not include within city travel.</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>If your Trip has Hill climbs, cab AC may be switched off during such climbs.</td>
                                    </tr>



                                </tbody>

                            </table>
                        </Modal.Body>
                    </Modal>
                </div >
            </main >

        </>
    )
}

export default MyAccount