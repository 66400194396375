import logo from './logo.svg';
import './App.css';
import Header from './components/Header/Header';
import About from './pages/About/About';
import Home from './pages/Home/Home';
import Help from './pages/Help/Help';
import Privacy from './pages/Privacy/Privacy';
import Terms from './pages/Terms/Terms';
import CarsList from './pages/CarsList/CarsList';
import Layout from './components/Layout/Layout';
import NoPage from './pages/NoPage/NoPage';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Redirect } from 'react-router'
import LoginPopup from './components/LoginPopup/LoginPopup';
import { useRef } from 'react'
import 'boxicons';
import { useSelector, useDispatch } from 'react-redux';
import { popupValue } from './services/reducerslice';
import { otppopupStatusSet } from './services/otpSlice';
import MyAccount from './pages/MyAccount/MyAccount';
import { useState, useEffect, React } from 'react';
import Contact from './pages/Contact/Contact';
import AddPartner from './pages/AddPartner/AddPartner';
import BookCab from './pages/BookCab/BookCab';
import OtpLogin from './components/OtpLogin/OtpLogin'
import { ToastContainer, toast, Flip } from 'react-toastify';
import { loggedInStatusSet } from './services/loggedinslice';
import TawkToWidget from './components/TawkToWidget/TawkToWidget';

function App() {
  const tawkMessengerRef = useRef();

  const dispatch = useDispatch();
  const popupState = useSelector(state => state.loginPopup.value);
  const checkLogin = useSelector(state => state.loggedIn.value)

  const [loggedIn, setLoggedIn] = useState(null);


  useEffect(() => {
    onLoad();
  }, [checkLogin]);

  async function onLoad() {
    try {
      let token = localStorage.getItem('token');
      if (checkLogin || token !== null) {
        setLoggedIn(true)
      } else {
        setLoggedIn(false)
      }

    } catch (e) {
      setLoggedIn(false)
    }
  }

  return (
    <>
      <TawkToWidget />
      {popupState && (
        <LoginPopup
          onCloseButtonClick={() => {
            dispatch(popupValue(false));
          }}
        />
      )}
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/rental-cars" element={<CarsList />} />
            <Route path="/rental-car-details" element={<BookCab />} />
            <Route path="/help" element={<Help />} />
            <Route path="/term-conditions" element={<Terms />} />
            <Route path="/privacy-policy" element={<Privacy />} />
            <Route path="/contact-us" element={<Contact />} />
            <Route path="/add-partner" element={<AddPartner />} />
            <Route path="/myaccount" element={loggedIn !== null && !loggedIn ? <Navigate to="/" /> : <MyAccount />} />
            {/* <Route path="/myaccount" element={<MyAccount />} /> */}
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        transition={Flip}>
      </ToastContainer>
    </>
  );
}
export default App;
