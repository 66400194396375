import React from 'react'
import useViewport from '../../components/Utilities/CustomViewPort'
import CarDetail from './CarDetail'
import CarDetailsMobile from './CarDetailsMobile'
import { useLocation, useNavigate } from 'react-router-dom';
function BookCab() {
    const { width } = useViewport();
    const location = useLocation();
    const navigate = useNavigate();
    const breakpoint = 620;
    if (location.state === null) {
        navigate('/');
    }

    return width < breakpoint ? <CarDetailsMobile /> : <CarDetail />;
}

export default BookCab