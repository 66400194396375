import React from 'react';


export const titleCase = (str) => {
    return str.toLowerCase().split(' ').map(function (word) {
        return word.replace(word[0], word[0]?.toUpperCase());
    }).join(' ');
}

export const currencyFormat = (num) => {
    return '₹' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}
