import React, { useState, useEffect, useRef } from 'react'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Modal from 'react-bootstrap/Modal';
import { homePageService } from '../../services/homePageService';
import * as moment from 'moment'
import AutoComplete from "react-google-autocomplete";
import { toast } from 'react-toastify';
import { json, useNavigate } from 'react-router-dom';
import mapsvg from '../../assets/images/map.svg'
import rupeesvg from '../../assets/images/rupee-svg.svg'
import { Helmet } from "react-helmet";
import SchemaOrg from '../../components/schema.org/SchemaOrg';

function Home() {
    const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;

    const navigate = useNavigate();

    const {
        isError, isLoading, isSuccess, data
    } = homePageService.useGetCarsbyTripQuery({ trip_type: '1', city_name: 'Bangalore Karnataka' });
    const postDataJson = {
        fromLocation:
        {
            locationName: "",
            latitude: "",
            longitude: ""
        },
        toLocation:
        {
            locationName: "",
            latitude: "",
            longitude: "",
            toLocationAll: []
        },
        pickupDate: new Date(Date.now() + 1 * (60 * 60 * 1000)),
        returnDate: new Date(new Date().setDate(new Date().getDate() + 1)),
        service_type: "oneway"
    };
    const dropdownRef1 = useRef(null);
    const [postData, setPostData] = useState(postDataJson);
    const [isInputFocused, setIsInputFocused] = useState(false);
    const [dateshow, setShow] = useState(false);
    const [returnDate, setReturnDate] = useState(new Date(new Date().setDate(new Date().getDate() + 1)));
    const [dateshowTodate, setShowtodate] = useState(false);
    const [selectedtripType, setTripType] = useState('oneway');
    const [todateMinDate, settodateMinDate] = useState(new Date())
    const [toLocations, setToLocations] = useState([]);

    const handleClose = () => {
        setShow(false); document.body.style.overflow = 'unset'; if (new Date(postData.pickupDate) < new Date()) {
            postData.pickupDate = new Date(Date.now() + 1 * (60 * 60 * 1000));
            setPostData(postData);
            return
        } return
    }
    const handleCloseToDate = () => { setShowtodate(false); document.body.style.overflow = 'unset'; return }
    const autocompleteOptions = {
        types: ['establishment'],
        componentRestrictions: { country: 'IN' }, // Change 'IN' to the desired country code
    };


    const selectedFromPlace = (place) => {
        postData.fromLocation.locationName = place.formatted_address;
        postData.fromLocation.latitude = place.geometry.location.lat();
        postData.fromLocation.longitude = place.geometry.location.lng();
        setPostData(postData);
    }


    const selectedToPlace = (place) => {
        postData.toLocation.locationName = place.formatted_address;
        postData.toLocation.latitude = place.geometry.location.lat();
        postData.toLocation.longitude = place.geometry.location.lng();
        setPostData(postData);
    }


    const selectedLocations = (place, index) => {
        setToLocations(toLocations =>
            toLocations.map(element =>
                element.id === index ? {
                    ...element, locationName: place.formatted_address,
                    latitude: place.geometry.location.lat(),
                    longitude: place.geometry.location.lng()
                } : element
            )
        );
    }

    const checkData = (e) => {
        if (e.target.id === 'fromLoc') {
            if (e.target.value === '') {
                postData.fromLocation.locationName = '';
                postData.fromLocation.latitude = '';
                postData.fromLocation.longitude = '';
            }
        }
        else if (e.target.id === 'toLoc') {
            if (e.target.value === '') {
                postData.toLocation.locationName = '';
                postData.toLocation.latitude = '';
                postData.toLocation.longitude = '';

            }
        }
        setPostData(postData);

    }
    const chooseTrip = (triptype) => {
        setTripType(triptype);
        setToLocations([]);
    }

    const handleShow = () => {
        setShow(true);
        setTimeout(
            () => {
                document.querySelectorAll(".react-datepicker__input-container input")[0].click()
                document.body.style.overflow = 'hidden';
            },
            100
        )
    }

    const todateShow = () => {
        setShowtodate(true);
        setTimeout(
            () => {
                document.querySelectorAll(".react-datepicker__input-container input")[0].click()
                document.body.style.overflow = 'hidden';
            },
            100
        )
    }

    const filterPassedTime = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);

        return currentDate.getTime() < selectedDate.getTime();
    };

    const resetTime = (date) => {
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0); // Reset milliseconds as well if needed
        return date;
    }

    const pickupDateChanged = (e) => {
        if (new Date(postData.pickupDate) < new Date()) {
            postData.pickupDate = new Date(Date.now() + 1 * (60 * 60 * 1000));
            setPostData(postData);
            return
        }

        if (new Date(postData.pickupDate) > new Date(postData.returnDate)) {
            setReturnDate('');
        }

        setReturnDate(new Date((postData.pickupDate).setDate((postData.pickupDate).getDate() + 1)))
        settodateMinDate(e)
        postData.pickupDate = e;
        setPostData(postData);
    }

    const returnDateChanged = (e) => {
        setReturnDate(e);
    }

    const addToLoc = () => {
        let index = toLocations.length - 1;
        let obj = {
            id: index + 1,
            locationName: "",
            latitude: "",
            longitude: ""
        }
        if (toLocations.length < 5) {
            setToLocations(toLocations => [...toLocations, obj]);
        }
    }

    const removeToLoc = () => {
        var array = [...toLocations];
        array.pop();
        setToLocations([...array]);
    }




    const submitForm = () => {
        if (!validateForm()) {
            return;
        }
        navigate(`/rental-cars`, { state: { userData: postData } });

    }

    const validateForm = () => {
        if (postData.fromLocation.locationName === '') {
            toast.error('Please select from location');
            return false;
        }
        if (postData.toLocation.locationName === '') {
            toast.error('Please select to location');
            return false;
        }


        if (selectedtripType === 'roundtrip') {
            for (let i = 0; i < postData.toLocationAll.length; i++) {
                if (postData.toLocationAll[i].locationName === '') {
                    let add = i + 2;
                    toast.error('Please select location ' + add);
                    return false;
                }
            }
        }
        if (postData.pickupDate === '') {
            toast.error('Please select pickup date');
            return false;
        }

        if (selectedtripType === 'roundtrip' && postData.returnDate === '') {
            toast.error('Please select return date');
            return false;
        }

        return true;

    }



    useEffect(() => {
        postData.returnDate = returnDate;
        postData.toLocationAll = toLocations;
        postData.service_type = selectedtripType;
        if (selectedtripType == 'oneway') {
            postData.returnDate = null;
        }

    }, [toLocations, postData, returnDate, data, selectedtripType]);

    if (isLoading) {
        return (
            <div className="loader-container">
                <div className="loader"></div>
            </div>
        );
    }


    return (
        <>
            <SchemaOrg />
            <Helmet>
                <title>Car Rental service | book a cab for outstation</title>

                <link rel="icon" href="favicon.ico" type="image/x-icon" />
                <meta name="description"
                    content=" Hirecabs Affordable and Cheapest fare Taxi services in and around Bangalore, We also provide airport pickup, Drop, outstation one way round trip car rental services in Bangalore" />
                <meta name="keywords"
                    content=" book a cab for outstation, Bangalore Cabs, Cabs in Bangalore, Taxi services nearby, Taxi services at lowest price, out stastion taxi Bangalore, Taxi near me cheap, local taxi services, Cab services in Bangalore, Taxi near me, one way outstation car rentals" />
                <meta name="keyphrase"
                    content="Taxi near me now, Taxi near my location, Taxi services nearby, Taxi services at lowest price, Taxi Bangalore, taxi service in Bangalore, Cab services in Bangalore, Taxi near me, , cab services bangalore, cab rental services in bangalore, taxi services, low cost car rental, car hire in bangalore,  cab hire, airport car rental, cheap cab for hire, taxi fare in bangalore" />
                <meta property="og:type" content="business.business" />
                <meta property="og:title" content="Most affordable Taxi services in Bangalore" />
                <meta property="og:description"
                    content="Find nearby taxi services at the lowest prices in Bangalore. hirecabs cabs provides the most Reliable , Affordable and Cheapest fare Taxi services in and around Bangalore, We also provide airport pickup, Drop, round trip and outstation cabs services in Bangalore." />
                <meta name="google-site-verification" content="XdmMXsbw0NHq_-nGHCDViua2k6RTMUs2RKHI" />
                <meta property="og:url" content="https://www.hirecabs.in" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />


            </Helmet>

            <main className="main">

                <div className="home">
                    <div className="home__bg"></div>

                    <div className="container">

                        <div className="row">
                            <div className="col-12">
                                <div className="home__content">
                                    <h1 className="home__title">Choose Your Trip <br /> Type Below</h1>

                                    {/* <p className="home__text">Select trip type
                                        </p> */}

                                    <div className='flex-inline'>
                                        <li onClick={() => chooseTrip('oneway')} className={"select-btn-trip one-way-svg " + (selectedtripType === 'oneway' ? 'selected' : '')}  ><span>One Way</span></li>
                                        <li className={"select-btn-trip round-trip-svg " + (selectedtripType === 'roundtrip' ? 'selected' : '')} onClick={() => chooseTrip('roundtrip')} ><span>Round Trip</span></li>
                                    </div>

                                    <form className="home__search" action="#">
                                        <div className="home__group">
                                            <label htmlFor="search1">Select From Location</label>
                                            {/* <input type="text" name="search1" id="search1" placeholder="Search" /> */}
                                            <AutoComplete
                                                apiKey={apiKey}
                                                options={autocompleteOptions}
                                                onBlur={(e) => checkData(e)}
                                                id='fromLoc'
                                                onPlaceSelected={(place) => selectedFromPlace(place)}
                                            />
                                        </div>
                                        <div className="home__group">
                                            <label htmlFor="search1">Select To Location</label>
                                            {/* <input type="text" name="search1" id="search1" placeholder="Search" /> */}
                                            <AutoComplete
                                                apiKey={apiKey}
                                                options={autocompleteOptions}
                                                id='toLoc'
                                                onPlaceSelected={(place) => selectedToPlace(place)}
                                            />
                                            {selectedtripType === 'roundtrip' && (
                                                <div className='action-to-place'>
                                                    <li onClick={removeToLoc}><box-icon name='minus-circle'></box-icon></li>
                                                    <li onClick={() => addToLoc()}><box-icon name='plus-circle'></box-icon></li>
                                                </div>
                                            )}
                                        </div>


                                        {toLocations && toLocations.length > 0 && toLocations.map((heading, index) =>
                                            <div className="home__group" key={index}>
                                                <label htmlFor="search{index}">Select To Location {index + 2} </label>
                                                {/* <input type="text" name="search1" id="search1" placeholder="Search" /> */}
                                                <AutoComplete
                                                    apiKey={apiKey}
                                                    options={autocompleteOptions}
                                                    onPlaceSelected={(place) => selectedLocations(place, index)}
                                                />

                                            </div>
                                        )}



                                        <div className="home__group">
                                            <label htmlFor="search2">Pickup Date</label>
                                            <div className='datepicker_div'>
                                                <input type="text" name="fromdate" value={postData.pickupDate ? moment(postData.pickupDate).format('DD-MMM-YYYY, hh:mm A') : ''} id="search2" readOnly onClick={handleShow} placeholder="Pickup Date & time" />
                                            </div>
                                        </div>
                                        {selectedtripType === 'roundtrip' && (
                                            <div className="home__group" >
                                                <label htmlFor="search3">Return Date</label>
                                                <input type="text" name="toDate" onClick={todateShow} id="search3" value={returnDate ? moment(returnDate).format('DD-MMM-YYYY, hh:mm A') : ''} readOnly placeholder="Return date & time" />
                                            </div>
                                        )}

                                        <button className={"search-btn " + (selectedtripType === '' ? 'disabled' : '')} type="button" onClick={submitForm}>
                                            <span>Search</span>
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="container">
                    <div className="row">

                        <div className="col-12">
                            <div className="main__title">
                                <h2>Four reasons to book a cab in hirecabs</h2>

                                <div className="row">
                                    <div className="col-12 col-xl-9">
                                        <p>At hirecabs, we believe in making your travel experience as smooth and enjoyable as possible. With a wide range of vehicles to choose from, convenient locations, and top-notch customer service, we're here to meet all your car rental needs.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="row">
                        <div className="col-12 col-md-6 col-xl-3">
                            <div className="feature">
                                <span className="feature__icon">
                                    <img src={mapsvg} width="26" height="26" alt="hirecabs" />
                                </span>
                                <h3 className="feature__title">Convenient Locations</h3>
                                <p className="feature__text">With multiple locations throughout Bangalore, picking up and returning your rental car is convenient and hassle-free.</p>
                            </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-3">
                            <div className="feature">
                                <span className="feature__icon feature__icon--pink">
                                    <img src={rupeesvg} width="26" height="26" alt="hirecabs" />
                                </span>
                                <h3 className="feature__title">Affordable prices</h3>
                                <p className="feature__text">Our prices are competitive and affordable. We strive to provide the best value for your money.</p>
                            </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-3">
                            <div className="feature">
                                <span className="feature__icon feature__icon--purple">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M11.5,20h-6a1,1,0,0,1-1-1V5a1,1,0,0,1,1-1h5V7a3,3,0,0,0,3,3h3v5a1,1,0,0,0,2,0V9s0,0,0-.06a1.31,1.31,0,0,0-.06-.27l0-.09a1.07,1.07,0,0,0-.19-.28h0l-6-6h0a1.07,1.07,0,0,0-.28-.19.29.29,0,0,0-.1,0A1.1,1.1,0,0,0,11.56,2H5.5a3,3,0,0,0-3,3V19a3,3,0,0,0,3,3h6a1,1,0,0,0,0-2Zm1-14.59L15.09,8H13.5a1,1,0,0,1-1-1ZM7.5,14h6a1,1,0,0,0,0-2h-6a1,1,0,0,0,0,2Zm4,2h-4a1,1,0,0,0,0,2h4a1,1,0,0,0,0-2Zm-4-6h1a1,1,0,0,0,0-2h-1a1,1,0,0,0,0,2Zm13.71,6.29a1,1,0,0,0-1.42,0l-3.29,3.3-1.29-1.3a1,1,0,0,0-1.42,1.42l2,2a1,1,0,0,0,1.42,0l4-4A1,1,0,0,0,21.21,16.29Z" /></svg>
                                </span>
                                <h3 className="feature__title">Expert Support, Anytime</h3>
                                <p className="feature__text">Our friendly and knowledgeable team is here to assist you with any questions or concerns you may have before, during, or after your rental.</p>
                            </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-3">
                            <div className="feature feature--last">
                                <span className="feature__icon feature__icon--green">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19.63,3.65a1,1,0,0,0-.84-.2,8,8,0,0,1-6.22-1.27,1,1,0,0,0-1.14,0A8,8,0,0,1,5.21,3.45a1,1,0,0,0-.84.2A1,1,0,0,0,4,4.43v7.45a9,9,0,0,0,3.77,7.33l3.65,2.6a1,1,0,0,0,1.16,0l3.65-2.6A9,9,0,0,0,20,11.88V4.43A1,1,0,0,0,19.63,3.65ZM18,11.88a7,7,0,0,1-2.93,5.7L12,19.77,8.93,17.58A7,7,0,0,1,6,11.88V5.58a10,10,0,0,0,6-1.39,10,10,0,0,0,6,1.39Z" /></svg>
                                </span>
                                <h3 className="feature__title">Our Fleet</h3>
                                <p className="feature__text">Discover our varied fleet, from compact cars to spacious SUVs and sleek sedans, all with modern features for your safety and comfort.</p>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-12'>
                            <h1>Bangalore's Premier Airport Taxi and Outstation Cab Services in India</h1>
                            <p>We are HireCabs Car Rentals, India's leading and most trusted cab rental provider. Whether you need a taxi for Airport Pickup, Airport Drop, Local Pickup and Drop, or travel to major outstations from Bangalore, we've got you covered. Our Airport Taxi Services are the ideal choice for all your airport transportation needs. Since 2019, we have been recognized as the largest and safest chauffeur-driven car rental company in India.</p>

                            <p>If you're looking to hire an Airport Taxi for Pickup or Drop, our fleet of smart and luxury vehicles is ready for you. As the most dependable Airport Taxi operators at Kempegowda International Airport, Bengaluru, we guarantee convenient, safe, reliable, accessible, and cost-effective travel in our well-maintained, air-conditioned Hatchbacks, Sedans, SUVs, Tempo Travellers, and Mini Buses. Our Airport Taxi Service is available for travel between Bengaluru Airport and the city.</p>

                            <p>Booking our Airport Taxi Service in Bangalore ensures you never have to wait for a cab or any other transport for Airport Pickup and Drop. Experience timely Airport Pickups and Drops in Bengaluru with our safe and dependable car rental service. We offer you the flexibility to choose from a wide range of vehicles for Airport Transfers based on your convenience and needs. Select your Airport Taxi from a variety of options like Innova, Dzire, Etios, Indigo, Indica, Xylo, Swift, Logan, Verito, Xcent, and more. Our Airport Taxis are available at affordable rates, day and night.</p>

                            <p> We prioritize customer safety and punctuality. Our services, including Airport Pickup & Drop, Outstation Cab Services, Local Trips, Holiday Packages, Corporate Cab Services, and Wedding Car Rentals, are designed to meet our customers' needs</p>

                            <h1> Book an Airport Taxi for Stress-Free Pickup and Drop. </h1>
                            <p>Our Airport Taxis in Bangalore are available for both business and leisure travelers, ensuring you never miss a flight or arrive late for a meeting again. With our on-time Airport Cabs, you can rely on us to pick you up or drop you off promptly, even if your flight is delayed. We offer luxury AC and Non-AC cars for dependable Airport Taxi Service in Bengaluru, providing comfort that surpasses what you might experience with other services.</p>

                            <p>Our goal is to deliver customer-focused Airport Taxi Services in Bangalore at the most competitive prices. Whether it's for Airport pick-ups, drops, or leisure trips, we have a wide range of clean, well-maintained vehicles to meet all your needs. Our Hatchbacks, Premium Sedans, SUVs, and Tempo Travellers are ideal for larger groups, ensuring your travel is smooth and seamless with our trustworthy Airport Taxi Service in Bengaluru.</p>

                            <p>Our Airport Taxi Services in Bangalore guarantee a safe and convenient journey from Kempegowda International Airport to any part of the city and vice versa. HireCabs is a well-known cab service provider in Bengaluru with over 13 years of experience in the car rental industry. You can easily hire an Outstation Cab or book an Airport Taxi in Bangalore online at <a href="http://www.hirecabs.in">www.Hirecabscabs.in</a> or by calling us at (+91 99722 57154) for a memorable journey.</p>

                            <h1>Safe and Secure Airport Transfer & Outstation Taxi Services in Bengaluru</h1>
                            <p>Are you a frequent traveler to and from Bangalore Airport? Looking for the best Airport Taxi Service provider? HireCabs offers Airport Taxi & Outstation Cab services at pocket-friendly prices. Instead of driving, why not relax and enjoy the ride in our chauffeur-driven Airport Taxi for your Airport Pickups and Drops? We believe your journey time should be entirely yours, which is why we offer chauffeur-driven cabs to major cities across India, helping you travel wherever you desire. Plan a weekend trip and explore everything around you with our affordable and reliable Outstation Car Rental Service in Bangalore.</p>

                            <p>To make planning your Outstation Trip easier, you can book a cab with ease on our website <a href="http://www.hirecabs.in">www.hirecabs.in</a>, or call us at (+91 99722 57154) if you'd like to discuss your itinerary in detail with our customer support team. Once you've booked an Outstation Taxi with us, we’ll send you the trip details. Our professional chauffeurs will guide you through some of the best experiences India has to offer. We assure you a great road trip when you book our Outstation Cab services in Bengaluru. Book our Outstation Cabs or Airport Taxi at affordable rates for a safe and convenient journey.</p>

                            <h1>HireCabs Provides Taxis for All Your Travel Needs</h1>
                            <p>Planning a weekend trip? Need to hire Outstation Cabs? Whether it’s an intercity ride, Airport Pickup, Drop, or an Outstation trip, our Outstation Cab services in Bangalore are your one-stop solution for all travel requirements. Our Outstation Taxi Services help you explore the best tourist destinations without the hassle of changing cabs or using public transport. Once you've planned an outstation trip, think of HireCabs for affordable Outstation Cab Services in Bengaluru.</p>

                            <p>Planning a trip to Ooty, Coonoor, Coorg, Pondicherry, Alleppey, Chennai, or any other popular outstation in India? No problem! We offer luxury cars ranging from Hatchbacks (Indica, Indica Vista, Ritz, Etios Liva, and Swift Hatchback), Sedans (Etios, Swift Dzire, Indigo, Logan, Verito, and Xcent), SUVs (Innova, Maruti Ertiga, Xylo, and Chevrolet Enjoy), to Tempo Travellers and Mini Buses for your outstation trip.</p>

                            <p>With HireCabs, you can hire our Outstation Cabs to reach popular destinations from anywhere without hassle. Our professional drivers are well-experienced and familiar with the routes to all tourist destinations in India, ensuring you never miss the best sightseeing spots along the way.</p>

                            <p>Ready to explore major tourist outstations by road? Our Outstation Cab Services will help you travel across India joyfully with your family and friends. With our Outstation Taxi Service, you can peacefully explore every tourist spot at all major outstations. Book Outstation Cabs with us at the lowest prices for a hassle-free experience from Bangalore to nearby destinations.</p>

                            <h1 className="font-size-28">Outstation Trip Car Rentals: Your Journey Starts Here</h1>
                            <p>Planning an outstation trip? HireCabs offers outstation car rentals with a wide selection of vehicles, including Innova, Dzire, Etios, and more. Whether you're heading to a nearby city or a long-distance destination, our outstation cabs provide the comfort and reliability you need for a stress-free journey.</p>

                            <h1 className="font-size-28">One-Way Trip Cabs: Affordable and Flexible</h1>
                            <p>For those who need a one-way trip, our one-way trip cabs are perfect for getting you to your destination without the extra cost of a round trip. Book your cab online and choose from our extensive fleet to suit your travel needs.</p>
                            <h1 className="font-size-28">
                                Nearby Cab Services Provider for Local Trips
                            </h1>
                            <p>HireCabs is also your local travel companion. As a nearby cab services provider, we offer local trips within Bangalore and beyond, ensuring you reach your destination on time, every time.</p>

                        </div>


                    </div>



                    <Modal show={dateshow} onHide={handleClose} className="datmodal">
                        <Modal.Body>
                            <DatePicker className="custom-datepicker" key={'fromdatekey'}
                                showTimeSelect
                                onChange={(e) => pickupDateChanged(e)}
                                timeFormat="h:mm aa"
                                selected={postData.pickupDate}
                                timeIntervals={15}
                                minDate={new Date()}
                                filterTime={filterPassedTime}
                                onCalendarClose={handleClose}
                                dateFormat="MMMM d, yyyy h:mm aa"
                                dateRender={(current) => {
                                    const style = {};
                                    // if (arr.includes(current)) {
                                    //   style.border = "1px solid red";
                                    //   style.borderRadius = "50%";
                                    // }
                                    return (
                                        <div className="ant-picker-cell-inner" style={style}>
                                            {current.date()}
                                        </div>
                                    );
                                }}
                            />
                        </Modal.Body>

                    </Modal >

                    <Modal show={dateshowTodate} onHide={handleCloseToDate} className="datmodal">
                        <Modal.Body>
                            <DatePicker className="custom-datepicker" key={'todatekey'}
                                showTimeSelect
                                onChange={(e) => returnDateChanged(e)}
                                timeFormat="h:mm aa"
                                minDate={postData.pickupDate}
                                selected={returnDate}
                                filterTime={filterPassedTime}
                                timeIntervals={15}
                                dateFormat="MMMM d, yyyy h:mm aa"
                                onCalendarClose={handleCloseToDate}
                                dateFormat="MMMM d, yyyy"
                                dateRender={(current) => {
                                    const style = {};
                                    // if (arr.includes(current)) {
                                    //   style.border = "1px solid red";
                                    //   style.borderRadius = "50%";
                                    // }
                                    return (
                                        <div className="ant-picker-cell-inner" style={style}>
                                            {current.date()}
                                        </div>
                                    );
                                }}
                            />
                        </Modal.Body>

                    </Modal >
                </div>
            </main >
        </>
    )
}

export default Home