import React, { useState } from 'react'
import './OtpLogin.scss';
import Modal from 'react-bootstrap/Modal';
import closesvg from '../../assets/images/close.svg'
import { useSelector, useDispatch } from 'react-redux';
import { otppopupStatusSet } from '../../services/otpSlice';
import { homePageService, useSaveLeadMutation, useLoginWithPassMutation, useRegUserMutation, useVerifyOtpMutation } from '../../services/homePageService';
import logoSvg from '../../assets/images/logo.svg';
import { click } from '@testing-library/user-event/dist/click';
import { toast } from 'react-toastify';


function OtpLogin(props: ModalProps) {
    const dispatch = useDispatch();
    const otppopupStatus = useSelector(state => state.otpPopup.value);
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [rider_name, setRiderName] = useState('');
    const [otpElement, setotpElement] = useState(false);
    const [otp, setOtp] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const [verifyOtpCall] = useVerifyOtpMutation();
    const [resendOtpflag, setResendOtpFlag] = useState(false);

    const handleChangeOtp = (event) => {
        setOtp(event.target.value);
    }
    const handlePhoneChange = (event) => {
        setPhone(event.target.value);
    }

    const handleNameChange = (event) => {
        setRiderName(event.target.value);
    }

    const handleOtpChange = (event) => {
        setOtp(event.target.value);
    }

    const resendOtp = (event) => {
        setResendOtpFlag(true)
        loginUserWithOtp();
    }


    const carData = []//location.state !== null ? location.state.carData : [];
    const bookData = []//location.state !== null ? location.state.bookData : [];

    const [createOrder] = useSaveLeadMutation();

    const verifyLogin = async () => {

        if (otp === '') {
            toast.error('Please enter OTP ');
            return false;
        }

        setIsLoading(true);
        try {

            let pdata = {
                mobile: phone,
                otp: otp,
                type: 3
            }
            // Dispatch the mutation with the new resource data
            const result = await verifyOtpCall(pdata);

            if (!result.data.status) {
                toast.error(result.data.message);
            }
            else if (result.data.status) {
                let resData = result.data;
                localStorage.setItem('name', resData.name)
                localStorage.setItem('email', resData.email)
                localStorage.setItem('phone', resData.phone)
                localStorage.setItem('token', resData.token)
                dispatch(otppopupStatusSet(false));
                window.location.reload();
            }

            // setCarData(carData => [...carData, { totalKm: result.data }]);
        } catch (error) {
            // Handle error
            console.error('Error creating resource:', error);
        }
        finally {
            setIsLoading(false) //to turn off loading

        }
    }


    const loginUserWithOtp = async () => {



        if (rider_name === '') {
            toast.error('Please enter your name');
            return false;
        }

        if (phone === '') {
            toast.error('Please enter Mobile No');
            return false;
        }


        const phoneRegex = /^\d{10}$/;

        if (!phoneRegex.test(phone)) {
            toast.error('Please enter valid Mobile No');
            return;
        }

        setIsLoading(true);

        try {

            let pdata = {
                rider_name: rider_name,
                rider_phone: phone,
                car: carData,
                order: bookData
            }

            // Dispatch the mutation with the new resource data
            const result = await createOrder(pdata);

            if (!result.data.success) {
                toast.error(result.data.msg);

            }
            else if (result.data.success) {
                toast.success('Booked Successfully!');

            }

            // setCarData(carData => [...carData, { totalKm: result.data }]);
        } catch (error) {
            // Handle error
            console.error('Error creating resource:', error);
        }
        finally {
            setIsLoading(false) //to turn off loading
        }
    }

    return (
        <>
            <Modal show={otppopupStatus} className="custom__modal modal__login" >
                {/* <Modal.Header>
                    <div className='imgclose' onClick={() => {
                        dispatch(otppopupStatusSet(false));
                    }}>
                        <img src={closesvg} />
                    </div>
                </Modal.Header> */}
                <Modal.Body>

                    <main className="main main--sign" data-bg="img/bg/bg.png">

                        <div className="sign">
                            <div className="sign__content">
                                <form action="#" className="sign__form">

                                    <div className='login__label' > Enter Your Details</div>
                                    <>
                                        {!otpElement && (
                                            <>
                                                <div className="sign__group">
                                                    <input type="text" className="sign__input" maxLength="10" className="sign__input" placeholder="Name" onChange={handleNameChange} />
                                                </div>

                                                <div className="sign__group">
                                                    <input type="text" className="sign__input" maxLength="10" pattern="[1-9]{1}[0-9]{9}" className="sign__input" placeholder="Mobile" onChange={handlePhoneChange} />
                                                </div>
                                            </>
                                        )}
                                        {otpElement && (
                                            <div className="sign__group">
                                                <input type="number" className="sign__input" placeholder="OTP" onChange={handleOtpChange} />

                                                {!resendOtpflag && (
                                                    <div className='resenotp' onClick={() => {
                                                        resendOtp();
                                                    }}>Resend OTP</div>
                                                )}
                                            </div>
                                        )}

                                        {!otpElement && (
                                            <button className="sign__btn" type="button" onClick={() => {
                                                loginUserWithOtp();
                                            }} ><span>Submit</span></button>
                                        )}
                                        {otpElement && (
                                            <button className="sign__btn" type="button" onClick={() => {
                                                verifyLogin();
                                            }} ><span>Submit</span></button>
                                        )}
                                    </>

                                </form>

                            </div>
                        </div>

                    </main>
                </Modal.Body>
            </Modal >

        </>
    )
}

export default OtpLogin