import React, { useState, useEffect } from 'react'
import { Formik, Field, ErrorMessage } from 'formik';
import { Link } from 'react-router-dom'
import * as Yup from 'yup';
import { homePageService, usePartnerRegMutation } from '../../services/homePageService';
import { Form, Button, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Helmet } from "react-helmet";
import SchemaOrg from '../../components/schema.org/SchemaOrg';
function AddPartner() {

    const [inputFields, setInputFields] = useState({
        username: "",
        email: "",
        phone: "",
        service_type: ""
    });
    const navigate = useNavigate();
    const location = useLocation();
    const [buttonText, setButtonText] = useState('Submit');
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [createPartner] = usePartnerRegMutation();

    const [regData, setRegData] = useState({});
    const [errors, setErrors] = useState({});
    const [submitting, setSubmitting] = useState(false);

    function validate(inputValues) {
        let errors = {};
        var emailregex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!inputValues.username) {
            errors.username = "Enter Your Name";
        }

        if (!inputValues.email) {
            errors.email = "Enter Your Email";
        }
        if (inputValues.email && !inputValues.email.match(emailregex)) {
            errors.email = "Enter Valid Email";
        }

        if (!inputValues.phone) {
            errors.phone = "Enter Your Mobile No.";
        }

        if (inputValues.phone && inputValues.phone.length != 10) {
            errors.phone = "Enter Valid Mobile No.";
        }

        if (!inputValues.service_type) {
            errors.service_type = "Please Select Service Type.";
        }

        return errors;
    }


    const handleSubmit = (event) => {
        setErrors(validate(inputFields));
        setSubmitting(true);
    }

    const formValues = async (values) => {
        try {
            // setIsLoading(true);
            setIsButtonDisabled(true);
            // Change button text
            setButtonText('Please Wait...');
            const result = await createPartner(values);
            if (!result.data.success) {
                toast.error(result.data.msg);
            }
            else if (result.data.success) {
                toast.success('Registration is successful! Our representative will connect you soon');
                navigate('/');
            }
        }
        catch (error) {
            // Handle error
            console.error('Error creating resource:', error);
        }
        finally {
            setIsButtonDisabled(false);
            // Change button text
            setButtonText('Submit');
        }

    }
    useEffect(() => {
        // console.log(regData);
    }, [regData]);

    if (isLoading) {
        return (
            <div className="loader-container">
                <div className="loader"></div>
            </div>
        );
    }


    return (
        <>
            <SchemaOrg />
            <Helmet>
                <title>Add Partner- HireCabs | Partner with Hirecabs | Attach your cab to Hirecabs
                </title>

                <link rel="canonical" href="https://www.hirecabs.in/add-partner" />
                <meta name="description"
                    content="Learn more add partner HireCabs, become partner with hirecabs to get more orders, it can be local one way outstaion airport pick and drop" />
                <meta name="keywords"
                    content="HireCabs, add partner HireCabs, partner as taxi service, tours travels partner, Bangalore cabs become ad partner, cab partner services" />
                <meta name="keyphrase"
                    content="near me taxi cab provider, Taxi near my location, Taxi services nearby, Taxi services at lowest price, Taxi Bangalore, taxi service in Bangalore, Cab services in Bangalore, Taxi near me, , cab services bangalore, cab rental services in bangalore, taxi services, low cost car rental, car hire in bangalore,  cab hire, airport car rental, cheap cab for hire, taxi fare in bangalore" />
                <meta property="og:type" content="business.business" />
                <meta property="og:title" content="add partner HireCabs - Your Trusted Taxi Service Provider in Bangalore" />
                <meta property="og:description"
                    content="Discover more add-partner HireCabs,Learn add partner , seamless online booking as partner, tie up with us as a partner" />
                <meta name="google-site-verification" content="XdmMXsbw0NHq_-nGHCDViua2k6RTMUs2RKHI" />
                <meta property="og:url" content="https://www.hirecabs.in/add-partner" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />

            </Helmet>

            <main className="main">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <ul className="breadcrumbs">
                                <li className="breadcrumbs__item"><Link to={'/'}>Home</Link></li>
                                <li className="breadcrumbs__item breadcrumbs__item--active">Add Partner</li>
                            </ul>
                        </div>

                        <div className="col-12 col-xl-11">
                            <div className="main__title main__title--page">
                                <h1>Partner Registration Form</h1>

                                <p>We are excited to collaborate with you and explore the possibilities of partnership. Please provide us with the following information to initiate the registration process. Your partnership with us signifies a commitment to mutual growth and success. Thank you for your interest in joining forces with hirecabs. </p>


                            </div>
                        </div>

                        <div className='partner-reg-form'>
                            <Formik
                                enableReinitialize
                                initialValues={inputFields}
                                validate={validate}
                                onSubmit={values => formValues(values)}

                            >
                                {(formik) =>

                                    <Form onSubmit={formik.handleSubmit}>
                                        <div className='row col-12'>
                                            <div className=' col-lg-6 col-12'>
                                                <div className="form-group">
                                                    <label>Name</label>
                                                    <input
                                                        type="text"
                                                        name="username"
                                                        onChange={formik.handleChange}
                                                        className={"sign__input " + (formik.errors.username ? 'highlight-error' : null)}
                                                    />
                                                    {formik.errors.username && (<p className="error-text">{formik.errors.username}</p>)}
                                                </div>
                                            </div>
                                            <div className=' col-lg-6 col-12'>
                                                <div className="form-group">
                                                    <label>E-Mail</label>
                                                    <input
                                                        type="text"
                                                        name="email"
                                                        onChange={formik.handleChange}
                                                        className={"sign__input " + (formik.errors.email ? 'highlight-error' : null)}
                                                    />
                                                    {formik.errors.email && (<p className="error-text">{formik.errors.email}</p>)}
                                                </div>
                                            </div>
                                            <div className=' col-lg-6 col-12'>
                                                <div className="form-group">
                                                    <label>Phone</label>
                                                    <input
                                                        type="text"
                                                        name="phone"
                                                        onChange={formik.handleChange}
                                                        className={"sign__input " + (formik.errors.phone ? 'highlight-error' : null)}
                                                    />
                                                    {formik.errors.phone && (<p className="error-text">{formik.errors.phone}</p>)}
                                                </div>
                                            </div>
                                            <div className=' col-lg-6 col-12'>
                                                <div className="form-group">
                                                    <label>Intrested In</label>
                                                    <Field as="select" name="color" name="service_type" className={"sign__input " + (formik.errors.phone ? 'highlight-error' : null)} onChange={formik.handleChange}>
                                                        <option disabled value="">Select </option>
                                                        <option value="one way">One Way Trip</option>
                                                        <option value="round trip">Round Trip</option>
                                                        <option value="both">Both</option>

                                                    </Field>

                                                    {formik.errors.service_type && (<p className="error-text">{formik.errors.service_type}</p>)}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group full-width-moobile">
                                            <button disabled={isButtonDisabled} className="primary-btn" type="submit"> {buttonText}</button>
                                        </div>
                                    </Form>

                                }
                            </Formik>

                        </div>

                    </div>
                </div>
            </main >


        </>
    )
}

export default AddPartner;