import React, { useState, useEffect, useRef } from 'react'
import { homePageService, useGetKmByLocMutation } from '../../services/homePageService';
import data1 from '../../assets/data/cars.json';
import CarItem from '../../views/CarItem/CarItem';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { useLocation } from 'react-router-dom';
import { click } from '@testing-library/user-event/dist/click';
import { useNavigate } from 'react-router-dom';
import sedanSVG from '../../assets/images/sedan.jpg';
import hatchBackSVG from '../../assets/images/hatchback.jpg';
import suvSVG from '../../assets/images/suv.jpg'
import rightarrow from '../../assets/images/breadcrumb.svg';
import './CarsListDesktop.scss';
import { titleCase } from '../../components/Utilities/Utilities'

function CarsListDesktop() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();
    if (location.state === null) {
        navigate('/');
    }

    const bookingData = location.state !== null ? location.state.userData : [];

    // const { isError, isLoading, isSuccess, data
    // } = homePageService.useGetCarsListQuery();


    const [newResourceData, setNewResourceData] = useState(bookingData);


    const [createResource] = useGetKmByLocMutation();
    const [data, setData] = useState([]);
    const [carData, setCarData] = useState([]);
    const [selectedSort, setSortType] = useState('');
    const [loaded, setLoaded] = useState(false)
    const [selectedModel, setSelectedModel] = useState('');
    const [searchTxt, setSearchTxt] = useState('');
    const [searchData, setSearchData] = useState([]);
    const [carModels, setCarModels] = useState([]);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [loading, setLoading] = useState(true);
    const [totalKm, settotalKm] = useState({});
    const dropdownRef = useRef(null);
    const options = [
        'Low', 'High',
    ];


    //    console.log(bookingData)

    const handleCreateResource = async () => {
        try {
            // Dispatch the mutation with the new resource data
            const result = await createResource(newResourceData);
            setData(result.data.res);
            setCarData(result.data.res);
            settotalKm(result.data.totalKm);
            // setCarData(carData => [...carData, { totalKm: result.data }]);
        } catch (error) {
            // Handle error
            console.error('Error creating resource:', error);
        }
        finally {
            setIsLoading(false) //to turn off loading
        }
    };


    const priceFilter = (e) => {
        setSortType(e.value);
        let sortOption = e.value === 'Low' ? true : false;
        let sortData = filterByPrice(carData, sortOption);
        setCarData(sortData);
    };

    const filterByPrice = (array, option) => {
        let sortedItems = [];
        if (option == true)
            sortedItems = [...array].sort((a, b) => a.totalAmount - b.totalAmount);
        else
            sortedItems = [...array].sort((a, b) => b.totalAmount - a.totalAmount);
        return sortedItems;
    };

    const handleClickOutside = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setIsDropdownVisible(false);
        }
    };

    const searchItems = (e) => {
        setSearchTxt(e.target.value);
        if (e.target.value.length > 3) {
            let keyword = e.target.value
            let searchItemsList;
            keyword = keyword.toLowerCase();
            searchItemsList = data.filter(item =>
                Object.values(item).some(value =>
                    value.toString().toLowerCase().includes(keyword)
                )
            );
            setSearchData(searchItemsList);
        }
        else if (e.target.value.length === 0) {
            setSearchData([]);
        }
        if (e.target.value.length > 0) {
            setIsDropdownVisible(true)
        } else {
            setIsDropdownVisible(false);
            resetFilter();
        }
    }

    const resetFilter = () => {
        setSortType('');
        setSelectedModel('');
        setSearchData([]);
        setCarData(data)
    }
    const filterSelectedModel = (e) => {
        let sortOption = selectedSort === 'Low' ? true : false;
        setSelectedModel(e.value);
        if (e.value == 'ALL') {
            let filterData = filterByPrice(data, sortOption);
            setCarData(filterData);
            return;
        }
        let filterData = data.filter((item) => item.car_type.includes(e.value));
        filterData = filterByPrice(filterData, sortOption)
        setCarData(filterData);
    };

    const filterSelectedCar = (id) => {
        let filterData = data.filter((item) => item.id.includes(id));
        setSortType('');
        setSelectedModel('');
        setCarData(filterData);

    }



    useEffect(() => {

        if (data && !loaded) {
            handleCreateResource();
            setCarData(data);
            setLoaded(true);
            setData(data);
            //    console.log(datakm);
        }

        if (data && data.length > 0) {
            let carModelList = [... new Set(data.map(item => {
                if (item.car_type !== undefined) {
                    return item.car_type;
                }
            }))].filter(function (item) {
                return typeof item === 'string' || typeof item === 'Number';
            });
            carModelList.unshift('ALL');
            setCarModels(carModelList);
        }

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };



    }, [carData, selectedSort, searchData, data]);

    if (isLoading) {
        return (
            <div className="loader-container">
                <div className="loader"></div>
            </div>
        );
    }




    // const filtersearchName = (array) => {
    //     return array.filter((item) => item.name.toLowerCase().indexOf(searchTerm.toLowerCase()) != -1);
    // };

    return (
        <>
            <main className="main">
                <div className="container">

                    <div className="row">


                        {/* <div className="col-12">
                            <div className="main__title main__title--page">
                                <h1>Choose the right lease</h1>
                            </div>
                        </div> */}

                        <div className="col-12">
                            <div className='wrap__border'>
                                <div className="main__filter">
                                    <form action="#" className="main__filter-search" ref={dropdownRef}>
                                        <input type="text" placeholder="Search..." onKeyUp={searchItems} />
                                        <button type="button" aria-label="Search"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.71,20.29,18,16.61A9,9,0,1,0,16.61,18l3.68,3.68a1,1,0,0,0,1.42,0A1,1,0,0,0,21.71,20.29ZM11,18a7,7,0,1,1,7-7A7,7,0,0,1,11,18Z" /></svg></button>
                                    </form>
                                    {isDropdownVisible && (
                                        <div className='filterList'>
                                            {searchData.map((result, index) => (
                                                <li key={index} onClick={() => filterSelectedCar(result.id)}>
                                                    <div className='search-item' >
                                                        {result.carName + ',' + result.brand}
                                                        <p>in {result.carType} </p>
                                                    </div>
                                                </li>
                                            ))}

                                        </div>
                                    )}
                                    {searchData.length == 0 && searchTxt.length > 0 && isDropdownVisible && (
                                        <div className='filterList no-result'>
                                            <li>
                                                No Results Found
                                            </li>
                                        </div>
                                    )}



                                    <div className="main__filter-wrap">

                                        <li>
                                            <Dropdown options={options} onChange={priceFilter} placeholder="Budget" value={selectedSort} />
                                        </li>
                                        <li>
                                            <Dropdown options={carModels} onChange={filterSelectedModel} placeholder="Select Model" value={selectedModel} />
                                        </li>

                                    </div>

                                    <div className='cars-type-list'>
                                        <li className='selected'>
                                            <div className='sedan__svg round-div '>
                                                <img src={sedanSVG} />
                                            </div>
                                            <div className='car__name_small'>Sedan</div>
                                        </li>
                                        <li>
                                            <div className='hatchback__svg round-div'>
                                                <img src={hatchBackSVG} />
                                            </div>
                                            <div className='car__name_small'>HatchBack</div>
                                        </li>
                                        <li> <div className='suv__svg round-div'>
                                            <img src={suvSVG} />
                                        </div>
                                            <div className='car__name_small'>SUV</div>
                                        </li>


                                    </div>

                                </div>
                                <div className='trip_info_desktop'>

                                    <div>  {bookingData?.fromLocation.locationName.split(',').slice(0, 3).join(' ')}  </div>      <img src={rightarrow} className='img__as__icon' />
                                    <div>{bookingData.toLocation.locationName.split(',').slice(0, 3).join(' ')}
                                        {bookingData.toLocationAll.map((item, index) => (
                                            <>
                                                <img src={rightarrow} className='img__as__icon' />
                                                <span> {item.locationName.split(',').slice(0, 1)}
                                                </span>
                                            </>
                                        ))}

                                    </div>
                                    &nbsp;({bookingData.service_type === 'roundtrip' ? 'Round Trip' : 'One Way'})
                                </div>
                            </div>

                        </div>

                    </div>

                    <div className="row carlist-desktop">
                        {carData.length > 0 && carData.map((item, index) => (
                            <CarItem carDataItem={item} totalKm={totalKm} bookData={bookingData} key={item.car_id} />
                        ))}
                    </div>

                </div>
            </main>

        </>
    )
}

export default CarsListDesktop