import React, { useState, useEffect, useRef } from 'react'
import { homePageService, useGetKmByLocMutation } from '../../services/homePageService';
import data1 from '../../assets/data/cars.json';
import CarItem from '../../views/CarItem/CarItem';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { useLocation } from 'react-router-dom';
import { click } from '@testing-library/user-event/dist/click';
import { useNavigate } from 'react-router-dom';
import './CarsListMobile.scss'
import rightarrow from '../../assets/images/breadcrumb.svg';
import { titleCase } from '../../components/Utilities/Utilities';

function CarsListMobile() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();
    if (location.state === null) {
        navigate('/');
    }

    const bookingData = location.state !== null ? location.state.userData : [];

    // const { isError, isLoading, isSuccess, data
    // } = homePageService.useGetCarsListQuery();


    const [newResourceData, setNewResourceData] = useState(bookingData);


    const [createResource] = useGetKmByLocMutation();
    const [data, setData] = useState([]);
    const [carData, setCarData] = useState([]);
    const [selectedSort, setSortType] = useState('');
    const [loaded, setLoaded] = useState(false)
    const [selectedModel, setSelectedModel] = useState('');
    const [searchTxt, setSearchTxt] = useState('');
    const [searchData, setSearchData] = useState([]);
    const [carModels, setCarModels] = useState([]);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [loading, setLoading] = useState(true);
    const [totalKm, settotalKm] = useState({});
    const dropdownRef = useRef(null);
    const options = [
        'Low', 'High',
    ];

    const handleCreateResource = async () => {
        try {
            return await createResource(newResourceData);
        } catch (error) {
            console.error('Error creating resource:', error);
        }
        finally {
            setIsLoading(false) //to turn off loading
        }
    };

    const priceFilter = (e) => {
        setSortType(e.value);
        let sortOption = e.value === 'Low' ? true : false;
        let sortData = filterByPrice(carData, sortOption);
        setCarData(sortData);
    };

    const filterByPrice = (array, option) => {
        let sortedItems = [];
        if (option == true)
            sortedItems = [...array].sort((a, b) => a.totalAmount - b.totalAmount);
        else
            sortedItems = [...array].sort((a, b) => b.totalAmount - a.totalAmount);
        return sortedItems;
    };

    const handleClickOutside = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setIsDropdownVisible(false);
        }
    };

    const searchItems = (e) => {
        setSearchTxt(e.target.value);
        if (e.target.value.length > 3) {
            let keyword = e.target.value
            let searchItemsList;
            keyword = keyword.toLowerCase();
            searchItemsList = data.filter(item =>
                Object.values(item).some(value =>
                    value.toString().toLowerCase().includes(keyword)
                )
            );
            setSearchData(searchItemsList);
        }
        else if (e.target.value.length === 0) {
            setSearchData([]);
        }
        if (e.target.value.length > 0) {
            setIsDropdownVisible(true)
        } else {
            setIsDropdownVisible(false);
            resetFilter();
        }
    }

    const resetFilter = () => {
        setSortType('');
        setSelectedModel('');
        setSearchData([]);
        setCarData(data)
    }
    const filterSelectedModel = (value) => {
        let sortOption = selectedSort === 'Low' ? true : false;
        setSelectedModel(value);
        let filterData = data.filter((item) => item.car_name.toLowerCase().includes(value.toLowerCase()));
        filterData = filterByPrice(filterData, sortOption)
        setCarData(filterData);
    };

    const filterSelectedCar = (id) => {
        let filterData = data.filter((item) => item.id.includes(id));
        setSortType('');
        setSelectedModel('');
        setCarData(filterData);
    }

    const listCars = () => {
        const uniqueCars = {};
        data.forEach(item => {
            if (!uniqueCars[item.car_name]) {
                uniqueCars[item.car_name] = {
                    car_name: item.car_name,
                    img: item.carimage,
                    id: item.id,
                    totalAmount: item.totalAmount,
                };
            }
        });
        const uniqueCarsArray = Object.values(uniqueCars);
        uniqueCarsArray.sort((a, b) => a.totalAmount - b.totalAmount);
        setCarModels(uniqueCarsArray);
    }



    useEffect(() => {

        handleCreateResource().then((result) => {
            setData(result.data.res);
            setIsLoading(false);
            setCarData(result.data.res);
            settotalKm(result.data.totalKm);

        })

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };



    }, []);

    useEffect(() => {
        listCars();
    }, [data])

    useEffect(() => {
        //  console.log(carData);
    }, [carData])



    useEffect(() => {
        filterSelectedModel(carModels[0]?.car_name.toLowerCase())
        // setSelectedModel(carModels[0]?.car_name);
    }, [carModels])

    if (isLoading) {
        return (
            <div className="loader-container">
                <div className="loader"></div>
            </div>
        );
    }




    // const filtersearchName = (array) => {
    //     return array.filter((item) => item.name.toLowerCase().indexOf(searchTerm.toLowerCase()) != -1);
    // };

    return (
        <>
            <main className="main">
                <div className="container">
                    <div className="row">


                        {/* <div className="col-12">
                            <div className="main__title main__title--page">
                                <h1>Choose the right lease</h1>
                            </div>
                        </div> */}



                        <div className="col-12">
                            <div className='back_trip_name'>
                                <li onClick={() => navigate(-1)}><box-icon name='left-arrow-alt' type='regular' size='24px' ></box-icon></li>
                                <li className='align-center'>{bookingData.service_type && bookingData.service_type === 'roundtrip' ? 'Round Trip' : 'One Way'}</li>
                            </div>
                            <div className='wrap__filter'>
                                <div className='trip_info_badge'>
                                    <li> <box-icon name='circle' type='solid' size='10px' color='green'  ></box-icon>      {bookingData?.fromLocation.locationName.split(',').slice(0, 3).join(' ')} </li>
                                    <li> <box-icon name='circle' type='solid' size='10px' color='red' ></box-icon>&nbsp;{bookingData.toLocation.locationName.split(',').slice(0, 3).join(' ')}
                                        {bookingData.toLocationAll.map((item, index) => (
                                            <>
                                                <img src={rightarrow} className='img__as__icon' />
                                                <span> {item.locationName.split(',').slice(0, 1)}
                                                </span>
                                            </>
                                        ))}

                                    </li>
                                </div>
                                <div className="main__filter">

                                    <div className='cars-type-list'>
                                        {carModels && carModels.map((item, index) => (
                                            <li className={selectedModel === item.car_name ? 'selected' : ''} key={index} >
                                                <div className='car__name_in_filter'>{titleCase(item.car_name)}</div>
                                                <div className='sedan__svg round-div' onClick={() => filterSelectedModel(item.car_name)} >
                                                    <img src={item.img} />
                                                </div>
                                                <div className='car__name_small'>₹ {item.totalAmount}</div>
                                            </li>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="row">
                        {carData && carData.map((item, index) => (
                            <CarItem carDataItem={item} totalKm={totalKm} bookData={bookingData} key={item.id} />
                        ))}
                    </div>


                    {/* <div className="row">
                        <div className="col-12">
                            <div className="paginator">
                                <span className="paginator__pages">6 from 129</span>

                                <ul className="paginator__list-mobile">
                                    <li>
                                        <a href="#">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17,11H9.41l3.3-3.29a1,1,0,1,0-1.42-1.42l-5,5a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l5,5a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L9.41,13H17a1,1,0,0,0,0-2Z" /></svg>
                                            <span>Prev</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <span>Next</span>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17.92,11.62a1,1,0,0,0-.21-.33l-5-5a1,1,0,0,0-1.42,1.42L14.59,11H7a1,1,0,0,0,0,2h7.59l-3.3,3.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l5-5a1,1,0,0,0,.21-.33A1,1,0,0,0,17.92,11.62Z" /></svg>
                                        </a>
                                    </li>
                                </ul>

                                <ul className="paginator__list">
                                    <li>
                                        <a href="#"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17,11H9.41l3.3-3.29a1,1,0,1,0-1.42-1.42l-5,5a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l5,5a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L9.41,13H17a1,1,0,0,0,0-2Z" /></svg></a>
                                    </li>
                                    <li className="active"><a href="#">1</a></li>
                                    <li><a href="#">2</a></li>
                                    <li><a href="#">3</a></li>
                                    <li><a href="#">4</a></li>
                                    <li><span>...</span></li>
                                    <li><a href="#">18</a></li>
                                    <li><a href="#">19</a></li>
                                    <li>
                                        <a href="#"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17.92,11.62a1,1,0,0,0-.21-.33l-5-5a1,1,0,0,0-1.42,1.42L14.59,11H7a1,1,0,0,0,0,2h7.59l-3.3,3.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l5-5a1,1,0,0,0,.21-.33A1,1,0,0,0,17.92,11.62Z" /></svg></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div> */}

                </div>
            </main >

        </>
    )
}

export default CarsListMobile