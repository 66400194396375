import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from "react-helmet";
import SchemaOrg from '../../components/schema.org/SchemaOrg';
function Privacy() {
    return (
        <>
            <SchemaOrg />
            <Helmet>
                <title>Privacy Policy HireCabs |  HireCabs Privacy Policy | Privacy Policy Page Hirecabs
                </title>
                <link rel="icon" href="favicon.ico" type="image/x-icon" />
                <link rel="canonical" href="https://www.hirecabs.in/privacy-policy" />
                <meta name="description"
                    content="Privacy Policy HireCabs " />
                <meta name="keywords"
                    content="hirecab Privacy Policy, hirecabs Privacy Policy  page, hirecabs Privacy Policy" />
                <meta name="keyphrase"
                    content="Privacy Policy of hirecabs, Privacy Policy of Hire Cabs" />
                <meta property="og:type" content="business.business" />
                <meta property="og:title" content="Privacy Policy HireCabs - Your Trusted Taxi Service Provider in Bangalore" />
                <meta property="og:description"
                    content="get hirecabs Privacy Policy page" />
                <meta name="google-site-verification" content="XdmMXsbw0NHq_-nGHCDViua2k6RTMUs2RKHI" />
                <meta property="og:url" content="https://www.hirecabs.in/privacy-policy" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <link rel="apple-touch-icon" href="apple-touch-icon.png" />
            </Helmet>
            <main className="main">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <ul className="breadcrumbs">
                                <li className="breadcrumbs__item"><Link to={'/'}>Home</Link></li>
                                <li className="breadcrumbs__item breadcrumbs__item--active">Privacy Policy</li>
                            </ul>
                        </div>

                        <div className="col-12 col-xl-11 termspage">
                            <div className="main__title main__title--page">
                                <h2>Privacy Policy for HireCabs</h2>
                            </div>
                            <h2>1. Introduction</h2>
                            <p>Welcome to HireCabs. We value your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our cab booking services.</p>

                            <h2>2. Information We Collect</h2>
                            <p>We may collect the following types of information:</p>
                            <ul>
                                <li><strong>Personal Information:</strong> Name, email address, phone number, home address, and payment information.</li>
                                <li><strong>Booking Information:</strong> Details of your bookings, including pick-up and drop-off locations, ride history, and preferences.</li>
                                <li><strong>Usage Information:</strong> Information about how you use our app, including access times, pages viewed, and other interactions.</li>
                                <li><strong>Device Information:</strong> Information about your device, including IP address, operating system, browser type, and device identifiers.</li>
                                <li><strong>Location Information:</strong> Real-time location data when you use our services.</li>
                            </ul>

                            <h2>3. How We Use Your Information</h2>
                            <p>We may use the information we collect for various purposes, including to:</p>
                            <ul>
                                <li>Provide and maintain our services.</li>
                                <li>Process your bookings and payments.</li>
                                <li>Communicate with you about your bookings and our services.</li>
                                <li>Improve our app and services.</li>
                                <li>Personalize your experience.</li>
                                <li>Send you marketing and promotional materials.</li>
                                <li>Ensure the security and integrity of our services.</li>
                                <li>Comply with legal obligations.</li>
                            </ul>

                            <h2>4. Sharing Your Information</h2>
                            <p>We may share your information with:</p>
                            <ul>
                                <li><strong>Service Providers:</strong> Third-party vendors who perform services on our behalf, such as payment processing, data analysis, email delivery, and customer service.</li>
                                <li><strong>Drivers:</strong> Your information may be shared with drivers to facilitate your ride.</li>
                                <li><strong>Business Transfers:</strong> In connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business.</li>
                                <li><strong>Legal Requirements:</strong> When required by law or to respond to legal process, protect our users, maintain the security of our services, and enforce our terms.</li>
                            </ul>

                            <h2>5. Security of Your Information</h2>
                            <p>We use administrative, technical, and physical security measures to protect your personal information. Despite these efforts, no security measure is perfect or impenetrable, and we cannot guarantee the security of your information.</p>

                            <h2>6. Your Choices</h2>
                            <p>You have choices regarding your information:</p>
                            <ul>
                                <li><strong>Access and Update:</strong> You can access and update your personal information through your account settings.</li>
                                <li><strong>Marketing Communications:</strong> You can opt-out of receiving marketing communications from us by following the unsubscribe instructions in those communications.</li>
                                <li><strong>Location Information:</strong> You can disable location tracking through your device settings, but this may affect your ability to use our services.</li>
                            </ul>

                            <h2>7. Children's Privacy</h2>
                            <p>Our services are not intended for individuals under the age of 18. We do not knowingly collect personal information from children under 18. If we learn that we have collected personal information from a child under 18, we will take steps to delete such information.</p>

                            <h2>8. Changes to This Privacy Policy</h2>
                            <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our app and updating the "Effective Date" at the top. You are advised to review this Privacy Policy periodically for any changes.</p>

                            <h2>9. Contact Us</h2>
                            <p>If you have any questions about this Privacy Policy, please contact us at:</p>
                        </div>
                    </div>
                </div>
            </main>

        </>
    )
}

export default Privacy