
import React, { useState, useEffect } from 'react'
import acsvg from '../../assets/images/air-conditioner.svg'
import carsvg from '../../assets/images/car.svg'
import Modal from 'react-bootstrap/Modal';
import closesvg from '../../assets/images/close.svg'
import { Link } from 'react-router-dom';
import baggage from '../../assets/images/baggage.svg'
import { toast } from 'react-toastify';
import { json, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import OtpLogin from '../../components/OtpLogin/OtpLogin';
import { otppopupStatusSet } from '../../services/otpSlice';
import './CarItem.scss'
import useViewport from '../../components/Utilities/CustomViewPort';
import { Tooltip } from 'react-tooltip'

import { homePageService, useSaveLeadMutation, useLoginWithOtpMutation, useLoginWithPassMutation, useRegUserMutation, useVerifyOtpMutation } from '../../services/homePageService';
function CarItem(props) {
    const { width } = useViewport();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [saveleadOpen, setSaveleadOpen] = useState(false);
    const otppopupStatus = useSelector(state => state.otpPopup.value);
    const [carDataItem, setCarDataItem] = useState(props.carDataItem);
    const [bookingData, setBookingData] = useState(props.bookData)
    const [totalKm, settotalKm] = useState(props.totalKm);
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [userExist, setUserExist] = useState(false)
    const [password, setPassword] = useState('');
    const [rider_name, setRiderName] = useState('');
    const [otpElement, setotpElement] = useState(false);
    const [otp, setOtp] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [loginWithOtp] = useLoginWithOtpMutation();
    const [verifyOtpCall] = useVerifyOtpMutation();
    const [resendOtpflag, setResendOtpFlag] = useState(false);
    const breakpoint = 620;

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closesetSavelead = () => {
        setSaveleadOpen(false);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const bookingPage = (carDataItem) => {
        if (localStorage.getItem('token') === null) {
            setSaveleadOpen(true);
            return;
        }
        navigate(`/rental-car-details`, { state: { carData: carDataItem, bookData: bookingData, totalKm: totalKm } });
    }


    const handleChangeOtp = (event) => {
        setOtp(event.target.value);
    }
    const handlePhoneChange = (event) => {
        setPhone(event.target.value);
    }

    const handleNameChange = (event) => {
        setRiderName(event.target.value);
    }
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    }

    const handleOtpChange = (event) => {
        setOtp(event.target.value);
    }

    const resendOtp = (event) => {
        setResendOtpFlag(true)
        loginUserWithOtp();
    }

    const [createOrder] = useSaveLeadMutation();
    const loginUserWithOtp = async () => {

        if (rider_name === '') {
            toast.error('Please enter your name');
            return false;
        }

        if (phone === '') {
            toast.error('Please enter Mobile No');
            return false;
        }

        if (email === '') {
            toast.error('Please enter Email Id');
            return false;
        }

        const phoneRegex = /^\d{10}$/;

        if (!phoneRegex.test(phone)) {
            toast.error('Please enter valid Mobile No');
            return;
        }

        if (email !== '') {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(email)) {
                toast.error('Please enter valid email');
                return;
            }
        }

        setIsLoading(true);

        try {

            let pdata = {
                rider_name: rider_name,
                rider_phone: phone,
                rider_email: email,
                car: carDataItem,
                order: bookingData
            }

            // Dispatch the mutation with the new resource data
            const result = await createOrder(pdata);

            if (!result.data.success) {
                toast.error(result.data.msg);

            }
            else if (result.data.success) {
                toast.success('OTP Sent Successfully');
                if (result.data.userExist) {
                    setUserExist(true);
                }
                setotpElement(true);
            }

            // setCarData(carData => [...carData, { totalKm: result.data }]);
        } catch (error) {
            // Handle error
            console.error('Error creating resource:', error);
        }
        finally {
            setIsLoading(false) //to turn off loading
        }
    }


    const verifyLogin = async () => {

        if (otp === '') {
            toast.error('Please enter OTP ');
            return false;
        }

        setIsLoading(true);
        try {

            let pdata = {
                mobile: phone,
                otp: otp,
                type: 3
            }
            // Dispatch the mutation with the new resource data
            const result = await verifyOtpCall(pdata);

            if (!result.data.status) {
                toast.error(result.data.message);
            }
            else if (result.data.status) {
                bookingData.phone = phone;
                bookingData.rider_name = rider_name;
                setBookingData(bookingData);
                if (userExist) {
                    let resData = result.data;
                    localStorage.setItem('name', resData.name)
                    localStorage.setItem('email', resData.email)
                    localStorage.setItem('phone', resData.phone)
                    localStorage.setItem('token', resData.token)
                }
                navigate(`/rental-car-details`, { state: { carData: carDataItem, bookData: bookingData, totalKm: totalKm } });
            }

            // setCarData(carData => [...carData, { totalKm: result.data }]);
        } catch (error) {
            // Handle error
            console.error('Error creating resource:', error);
        }
        finally {
            setIsLoading(false) //to turn off loading

        }
    }

    useEffect(() => {
    }, [])

    {


        return (
            width < breakpoint ?
                <>

                    <div className="col-12 col-md-6 col-xl-4" >
                        <div className="car">
                            <div className="splide splide--card car__slider">
                                <div className="splide__track">
                                    <img src={carDataItem.carimage} />
                                </div>
                            </div>
                            <div className="car__title">
                                <h3 className="car__name">{carDataItem.car_name} </h3>
                                <span className="car__year">{carDataItem.car_type}</span>
                            </div>
                            <ul className="car__list">
                                <li>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12.3,12.22A4.92,4.92,0,0,0,14,8.5a5,5,0,0,0-10,0,4.92,4.92,0,0,0,1.7,3.72A8,8,0,0,0,1,19.5a1,1,0,0,0,2,0,6,6,0,0,1,12,0,1,1,0,0,0,2,0A8,8,0,0,0,12.3,12.22ZM9,11.5a3,3,0,1,1,3-3A3,3,0,0,1,9,11.5Zm9.74.32A5,5,0,0,0,15,3.5a1,1,0,0,0,0,2,3,3,0,0,1,3,3,3,3,0,0,1-1.5,2.59,1,1,0,0,0-.5.84,1,1,0,0,0,.45.86l.39.26.13.07a7,7,0,0,1,4,6.38,1,1,0,0,0,2,0A9,9,0,0,0,18.74,11.82Z" /></svg>
                                    <span>{carDataItem.available_seats} People</span>
                                </li>
                                <li>
                                    <img src={baggage} style={{ width: '20px', height: 'auto' }} />
                                    <span>&nbsp;3 Luggage</span>
                                </li>
                                <li>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19.088,4.95453c-.00732-.00781-.00952-.01819-.01715-.02582s-.01819-.00995-.02606-.01733a9.97886,9.97886,0,0,0-14.08948,0c-.00787.00738-.01837.00964-.02606.01733s-.00983.018-.01715.02582a10,10,0,1,0,14.1759,0ZM12,20a7.9847,7.9847,0,0,1-6.235-3H9.78027a2.9636,2.9636,0,0,0,4.43946,0h4.01532A7.9847,7.9847,0,0,1,12,20Zm-1-5a1,1,0,1,1,1,1A1.001,1.001,0,0,1,11,15Zm8.41022.00208L19.3999,15H15a2.99507,2.99507,0,0,0-2-2.81573V9a1,1,0,0,0-2,0v3.18427A2.99507,2.99507,0,0,0,9,15H4.6001l-.01032.00208A7.93083,7.93083,0,0,1,4.06946,13H5a1,1,0,0,0,0-2H4.06946A7.95128,7.95128,0,0,1,5.68854,7.10211l.65472.65473A.99989.99989,0,1,0,7.75732,6.34277l-.65466-.65466A7.95231,7.95231,0,0,1,11,4.06946V5a1,1,0,0,0,2,0V4.06946a7.95231,7.95231,0,0,1,3.89734,1.61865l-.65466.65466a.99989.99989,0,1,0,1.41406,1.41407l.65472-.65473A7.95128,7.95128,0,0,1,19.93054,11H19a1,1,0,0,0,0,2h.93054A7.93083,7.93083,0,0,1,19.41022,15.00208Z" /></svg>
                                    <span>{carDataItem?.extra_per_km_charges_with_gst_company} / Per km</span>
                                </li>
                                <li>
                                    <img src={acsvg} style={{ width: '20px', height: 'auto' }} />
                                    <span> &nbsp; AC  </span>
                                </li>
                            </ul>

                            <div className="car__footer">
                                <span className="car__price"> ₹ {carDataItem.totalAmount} <sub><strike>₹  {parseInt(carDataItem.grand_total)} </strike></sub> <br />
                                    <span style={{ fontSize: '14px' }}>Upto {totalKm} Km Inclusive GST</span>
                                    <br />  <sub style={{ color: '#28608fb3', cursor: 'pointer' }} onClick={openModal}>view details</sub></span>
                                <a className="car__more" onClick={() => bookingPage(carDataItem)} ><span>Book now</span></a>
                            </div>
                        </div>
                    </div >


                    <Modal show={isModalOpen} onHide={closeModal} className="custom__modal">
                        <Modal.Header>
                            <div onClick={closeModal} className='imgclose'><img src={closesvg} /></div>
                        </Modal.Header>
                        <Modal.Body>
                            <table className='table_style'>
                                <tbody>
                                    <tr>
                                        <th colSpan={2}>Fare Breakup</th>
                                    </tr>
                                    <tr>
                                        <th>Paticular</th>
                                        <th>Fare</th>
                                    </tr>
                                    <tr>
                                        <td>Total Amt</td>
                                        <td>₹{carDataItem.totalAmount}</td>
                                    </tr>
                                    <tr>
                                        <th colSpan={2}>INCLUDES</th>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>Base Fare Up to {parseInt(carDataItem.min_km_included) > parseInt(totalKm) ? carDataItem.min_km_included : totalKm} km</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>Driver day bata and GST</td>
                                    </tr>
                                    <tr>
                                        <th colSpan={2}>EXCLUDES</th>
                                    </tr>

                                    <tr>
                                        <td colSpan={2}>Pay ₹ {carDataItem?.extra_per_km_charges_with_gst_company} / km after {parseInt(carDataItem.min_km_included) > parseInt(totalKm) ? carDataItem.min_km_included : totalKm} km</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>Tolls and Parking (Permits or Intercity tax)</td>
                                    </tr>

                                    <tr>
                                        <th colSpan={2}>TERMS</th>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>Your Trip has a KM limit. If your usage exceeds this limit, you will be charged for the excess KM used.</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>The Airport Entry/Parking charge, if applicable is not included in the fare and will be charged extra.</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>Your trip includes one pick up in Pick-up city and one drop to destination city. It does not include within city travel.</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>If your Trip has Hill climbs, cab AC may be switched off during such climbs.</td>
                                    </tr>
                                </tbody>


                            </table>
                        </Modal.Body>
                    </Modal >


                    <Modal show={saveleadOpen} className="custom__modal modal__login" >
                        <Modal.Header>
                            <div className='imgclose' onClick={() => {
                                setSaveleadOpen(false);
                            }}>
                                <img src={closesvg} />
                            </div>
                        </Modal.Header>
                        <Modal.Body>

                            <main className="main main--sign" data-bg="img/bg/bg.png">

                                <div className="sign">
                                    <div className="sign__content">
                                        <form action="#" className="sign__form">
                                            {!otpElement && (
                                                <div className='login__label' > Enter Your Details</div>
                                            )}
                                            {otpElement && (
                                                <div className='login__label' > Enter OTP</div>

                                            )}
                                            <>
                                                {!otpElement && (
                                                    <>
                                                        <div className="sign__group">
                                                            <input type="text" className="sign__input" maxLength="10" className="sign__input" placeholder="Name" onChange={handleNameChange} />
                                                        </div>

                                                        <div className="sign__group">
                                                            <input type="text" className="sign__input" maxLength="10" pattern="[1-9]{1}[0-9]{9}" className="sign__input" placeholder="Mobile" onChange={handlePhoneChange} />
                                                        </div>
                                                        <div className="sign__group">
                                                            <input type="email" className="sign__input" className="sign__input" placeholder="Email" onChange={handleEmailChange} />
                                                        </div>
                                                    </>
                                                )}
                                                {otpElement && (
                                                    <div className="sign__group">
                                                        <input type="number" className="sign__input" placeholder="OTP" onChange={handleOtpChange} />

                                                        {!resendOtpflag && (
                                                            <div className='resenotp' onClick={() => {
                                                                resendOtp();
                                                            }}>Resend OTP</div>
                                                        )}
                                                    </div>
                                                )}

                                                {!otpElement && !isLoading && (
                                                    <button className="sign__btn" type="button" onClick={() => {
                                                        loginUserWithOtp();
                                                    }} ><span>Submit</span></button>
                                                )}

                                                {!otpElement && isLoading && (
                                                    <button className="sign__btn" type="button" ><span>Please Wait..</span></button>
                                                )}
                                                {otpElement && (
                                                    <button className="sign__btn" type="button" onClick={() => {
                                                        verifyLogin();
                                                    }} ><span>Submit</span></button>
                                                )}

                                            </>

                                        </form>

                                    </div>
                                </div>

                            </main>
                        </Modal.Body>
                    </Modal >
                </>
                :
                <>
                    <div className="list_cars_desktop">
                        <div className="items-item">
                            <div className="cab-item">
                                <div className="item car-img-text ">
                                    <img src={carDataItem.carimage} alt={carDataItem.car_name} />
                                    <li><div>{carDataItem.car_name} </div>
                                        <span className='overtooltip'>or Similar <box-icon data-tooltip-id="my-tooltip" data-tooltip-content={carDataItem.similarcarstxt} name='info-circle' data-tooltip-place="right"> </box-icon>
                                        </span>
                                        <Tooltip id="my-tooltip" className='set_tool_tip' />


                                    </li>

                                </div>
                                <div className="divbg-gray">
                                    <div className="item car-details-usage">
                                        <ul className="car__list car__list__desktop">
                                            <li>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12.3,12.22A4.92,4.92,0,0,0,14,8.5a5,5,0,0,0-10,0,4.92,4.92,0,0,0,1.7,3.72A8,8,0,0,0,1,19.5a1,1,0,0,0,2,0,6,6,0,0,1,12,0,1,1,0,0,0,2,0A8,8,0,0,0,12.3,12.22ZM9,11.5a3,3,0,1,1,3-3A3,3,0,0,1,9,11.5Zm9.74.32A5,5,0,0,0,15,3.5a1,1,0,0,0,0,2,3,3,0,0,1,3,3,3,3,0,0,1-1.5,2.59,1,1,0,0,0-.5.84,1,1,0,0,0,.45.86l.39.26.13.07a7,7,0,0,1,4,6.38,1,1,0,0,0,2,0A9,9,0,0,0,18.74,11.82Z" /></svg>
                                                <span>{carDataItem.available_seats} People</span>
                                            </li>
                                            <li>
                                                <img src={baggage} style={{ width: '20px', height: 'auto' }} />
                                                <span>&nbsp;3 Luggage</span>
                                            </li>
                                            <li>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19.088,4.95453c-.00732-.00781-.00952-.01819-.01715-.02582s-.01819-.00995-.02606-.01733a9.97886,9.97886,0,0,0-14.08948,0c-.00787.00738-.01837.00964-.02606.01733s-.00983.018-.01715.02582a10,10,0,1,0,14.1759,0ZM12,20a7.9847,7.9847,0,0,1-6.235-3H9.78027a2.9636,2.9636,0,0,0,4.43946,0h4.01532A7.9847,7.9847,0,0,1,12,20Zm-1-5a1,1,0,1,1,1,1A1.001,1.001,0,0,1,11,15Zm8.41022.00208L19.3999,15H15a2.99507,2.99507,0,0,0-2-2.81573V9a1,1,0,0,0-2,0v3.18427A2.99507,2.99507,0,0,0,9,15H4.6001l-.01032.00208A7.93083,7.93083,0,0,1,4.06946,13H5a1,1,0,0,0,0-2H4.06946A7.95128,7.95128,0,0,1,5.68854,7.10211l.65472.65473A.99989.99989,0,1,0,7.75732,6.34277l-.65466-.65466A7.95231,7.95231,0,0,1,11,4.06946V5a1,1,0,0,0,2,0V4.06946a7.95231,7.95231,0,0,1,3.89734,1.61865l-.65466.65466a.99989.99989,0,1,0,1.41406,1.41407l.65472-.65473A7.95128,7.95128,0,0,1,19.93054,11H19a1,1,0,0,0,0,2h.93054A7.93083,7.93083,0,0,1,19.41022,15.00208Z" /></svg>
                                                <span>{carDataItem?.extra_per_km_charges_with_gst_company} / Per km</span>
                                            </li>
                                            <li>
                                                <img src={acsvg} style={{ width: '20px', height: 'auto' }} />
                                                <span> &nbsp; AC  </span>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="item car-gst">
                                        <b className="color-green" style={{ fontSize: '18px' }}>₹{carDataItem.totalAmount}</b><br />
                                        <span style={{ fontSize: '14px' }}>Upto {totalKm} Km Inclusive GST</span>
                                        <br /> <span className="details-toggle" onClick={openModal}>Details</span>
                                    </div>
                                </div>
                                <div className="item car-select-btn show-desktop">
                                    <div className="dr-btn-continue margin-top-0 margin-bottom-1" onClick={() => bookingPage(carDataItem)}  >Select</div>
                                </div>
                            </div>
                        </div >
                    </div >


                    <Modal show={isModalOpen} onHide={closeModal} className="custom__modal">
                        <Modal.Header>
                            <div onClick={closeModal} className='imgclose'><img src={closesvg} /></div>
                        </Modal.Header>
                        <Modal.Body>
                            <table className='table_style'>
                                <tbody>
                                    <tr>
                                        <th colSpan={2}>Fare Breakup</th>
                                    </tr>
                                    <tr>
                                        <th>Paticular</th>
                                        <th>Fare</th>
                                    </tr>

                                    {/* {carDataItem.discountAmt && carDataItem.discountAmt > 0 && (
                        <tr>
                            <td>Discount</td>
                            <td>₹{carDataItem.discountAmt}</td>
                        </tr>
                    )} */}
                                    <tr>
                                        <td>Total Amt</td>
                                        <td>₹{carDataItem.totalAmount}</td>
                                    </tr>
                                    <tr>
                                        <th colSpan={2}>INCLUDES</th>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>Base Fare Up to {parseInt(carDataItem.min_km_included) > parseInt(totalKm) ? carDataItem.min_km_included : totalKm} km</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>Driver day bata and GST</td>
                                    </tr>
                                    <tr>
                                        <th colSpan={2}>EXCLUDES</th>
                                    </tr>

                                    <tr>
                                        <td colSpan={2}>Pay ₹ {carDataItem?.extra_per_km_charges_with_gst_company} / km after {parseInt(carDataItem.min_km_included) > parseInt(totalKm) ? carDataItem.min_km_included : totalKm} km</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>Tolls and Parking (Permits or Intercity tax)</td>
                                    </tr>

                                    <tr>
                                        <th colSpan={2}>TERMS</th>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>Your Trip has a KM limit. If your usage exceeds this limit, you will be charged for the excess KM used.</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>The Airport Entry/Parking charge, if applicable is not included in the fare and will be charged extra.</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>Your trip includes one pick up in Pick-up city and one drop to destination city. It does not include within city travel.</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>If your Trip has Hill climbs, cab AC may be switched off during such climbs.</td>
                                    </tr>



                                </tbody>

                            </table>
                        </Modal.Body>
                    </Modal >


                    <Modal show={saveleadOpen} className="custom__modal modal__login" >
                        <Modal.Header>
                            <div className='imgclose' onClick={() => {
                                setSaveleadOpen(false);
                            }}>
                                <img src={closesvg} />
                            </div>
                        </Modal.Header>
                        <Modal.Body>

                            <main className="main main--sign" data-bg="img/bg/bg.png">
                                <div className="sign">
                                    <div className="sign__content">
                                        <form action="#" className="sign__form">
                                            {!otpElement && (
                                                <div className='login__label' > Enter Your Details</div>
                                            )}

                                            {otpElement && (
                                                <div className='login__label' > Enter OTP </div>
                                            )}
                                            <>
                                                {!otpElement && (
                                                    <>
                                                        <div className="sign__group">
                                                            <input type="text" className="sign__input" maxLength="10" className="sign__input" placeholder="Name" onChange={handleNameChange} />
                                                        </div>

                                                        <div className="sign__group">
                                                            <input type="text" className="sign__input" maxLength="10" pattern="[1-9]{1}[0-9]{9}" className="sign__input" placeholder="Mobile" onChange={handlePhoneChange} />
                                                        </div>
                                                        <div className="sign__group">
                                                            <input type="email" className="sign__input" className="sign__input" placeholder="Email" onChange={handleEmailChange} />
                                                        </div>
                                                    </>
                                                )}
                                                {otpElement && (
                                                    <div className="sign__group">
                                                        <input type="number" className="sign__input" placeholder="OTP" onChange={handleOtpChange} />

                                                        {!resendOtpflag && (
                                                            <div className='resenotp' onClick={() => {
                                                                resendOtp();
                                                            }}>Resend OTP</div>
                                                        )}
                                                    </div>
                                                )}

                                                {!otpElement && !isLoading && (
                                                    <button className="sign__btn" type="button" onClick={() => {
                                                        loginUserWithOtp();
                                                    }} ><span>Submit</span></button>
                                                )}

                                                {!otpElement && isLoading && (
                                                    <button className="sign__btn" type="button" ><span>Please Wait..</span></button>
                                                )}
                                                {otpElement && (
                                                    <button className="sign__btn" type="button" onClick={() => {
                                                        verifyLogin();
                                                    }} ><span>Submit</span></button>
                                                )}
                                            </>

                                        </form>

                                    </div>
                                </div>

                            </main>
                        </Modal.Body>
                    </Modal >

                </>


        )
    }

}

export default CarItem