import React from 'react'
import useViewport from '../../components/Utilities/CustomViewPort'
import CarsListDesktop from '../CarsListDesktop/CarsListDesktop';
import CarsListMobile from '../CarsListMobile/CarsListMobile'
import { useLocation, useNavigate } from 'react-router-dom';
function CarsList() {

    const { width } = useViewport();
    const breakpoint = 620;
    const location = useLocation();
    const navigate = useNavigate();

    if (location.state === null) {
        navigate('/');
    }

    return width < breakpoint ? <CarsListMobile /> : <CarsListDesktop />;

}

export default CarsList